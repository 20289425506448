import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Modal, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import ImageIcon from '@mui/icons-material/Image';
import Div from '@jumbo/shared/Div';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import { Slide } from 'react-slideshow-image';
import SweetAlertConfirm from './SweetAlertConfirm';
import { useNavigate } from 'react-router-dom';

export default function ManageTranding() {
    const nav = useNavigate()
    const [data, setData] = useState([])
    const [link, SetLink] = useState('')
    const [categoryName, setCategoryName] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState([]);
    const [alertData, setalertData] = useState({ show: false, message: "", variant: "", });
    const [confirmDelete, setConfirmDelete] = useState({ show: false, id: null, processing: false });


    const CategoryData = () => {
        setLoading(true);
        postRequest("/gettrendingCategory", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => {
                    let thumbnails = [];
                    try {
                        // Use regex to clean up the extra backslashes
                        const cleanedThumbnails = row?.thumbnails
                            ? row?.thumbnails
                                .replace(/\\{2,}"/g, '"') // Remove extra escaping before double quotes
                                .replace(/"\\{2,}/g, '"') // Remove escaping after double quotes
                            : "[]";

                        // Parse the cleaned JSON string
                        thumbnails = JSON.parse(cleanedThumbnails).map(src => link + src);
                    } catch (e) {
                        console.error("Error parsing thumbnails JSON:", e);
                    }

                    return {
                        id: index + 1,
                        categoryId: row?.id || '',
                        title: row?.title || '',
                        categoryNames: row?.categoryNames?.join(", ") || "",
                        thumbnails: thumbnails,
                    };
                });
                // console.log('formattedData', formattedData)
                // console.log('response?.data?.link', response?.data?.link)
                setData(formattedData);
                SetLink(response?.data?.link)
                setLoading(false);
            }
            if (response?.data?.status === "success") {
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        CategoryData()
    }, [])


    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const handleImageClick = (imageUrlArray, categoryNames) => {
        setImageSrc(imageUrlArray);
        setCategoryName(categoryNames.split(", ").map(category => category.trim()))
        setModalOpen(true);
    };

    const handleDelete = (id) => {
        // console.log('id', id)
        setConfirmDelete({ show: true, id, processing: true });
        postRequest("/deleteTrending", { id }, async (response) => {
            // setLoading(true);
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                // setLoading(false);
                setalertData({
                    show: true,
                    message: 'Trending deleted successfully',
                    variant: 'success'
                });
                // CategoryData()
                setData((prevData) => prevData.filter(trending => trending.categoryId !== id));
            }
            if (response?.data?.status === "error") {
                // setLoading(false);
                setalertData({
                    show: true,
                    message: 'Trending not deleted',
                    variant: 'error'
                });
            }
            setConfirmDelete({ show: false, id: null, processing: false });
        },
            (error) => {
                console.log(error?.response?.data);
            setConfirmDelete({ show: false, id: null, processing: false });
                // setLoading(false);
            }
        );
    };

    const handleDeleteClick = (id) => {
        setConfirmDelete({ show: true, id, processing: false });
    };

    const confirmDeleteAction = (confirm) => {
        if (confirm) {
            handleDelete(confirmDelete.id);
        }else{
            setConfirmDelete({ show: false, id: null, processing: false });
        }
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80 },
        { field: "title", headerName: "Title", width: 150,
            renderCell: (params) => (<Tooltip title={params.value} arrow>{params.value}</Tooltip>),
         },
        {
            field: "categoryNames", headerName: "Category Names", width: 250,
            renderCell: (params) => (<Tooltip title={params.value} arrow>{params.value}</Tooltip>),
        },

        {
            field: "thumbnails", headerName: "thumbnails", width: 150,
            renderCell: (params) => (
                <ImageIcon style={{ cursor: 'pointer' }} onClick={() => handleImageClick(params.value.map(src => link + src), params.row.categoryNames)} />
            )
        },
        {
            field: "categoryId", headerName: "Delete", width: 150,
            renderCell: (params) => (
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(params.value)} />
            )
        },
    ];

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    name: false,
                    icon: false,
                    banner: false,
                    status: false,
                },
            },
        },
    };


    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    const divStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '400px',
        position: 'relative'
    };

    const captionStyle = {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        textAlign: 'center',
        width: '90%',
    };

    return (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button variant="contained" color="primary" onClick={()=>{nav("/marketPlaceTrendingCategory")}}>
                    Add Trending
                </Button>
            </Box>
        <JumboDemoCard title={"Manage Trending"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            {confirmDelete.show && (
                <SweetAlertConfirm
                    alertData={{
                        show: true,
                        title: "Confirm Delete",
                        message: confirmDelete.processing ? "Deleting..." : "Are you sure you want to delete this category?",
                        variant: "warning",
                        processing: confirmDelete.processing,
                        actions: confirmDelete.processing ? [] : [
                            { label: "Cancel", action: () => confirmDeleteAction(false) },
                            { label: "Delete", action: () => confirmDeleteAction(true), color: "error" }
                        ]
                    }}
                    setAlertData={setConfirmDelete}
                />
            )}
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        // height:'50%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Slide>
                        {imageSrc.map((slideImage, index) => (
                            <div key={index} style={divStyle}>
                                <img src={slideImage} alt={`Image ${index + 1}`} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                <div style={captionStyle}>{categoryName[index]}</div>
                            </div>
                        ))}
                    </Slide>
                </Box>
            </Modal >
        </JumboDemoCard >
        </>
    )
}
