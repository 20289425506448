import React, { useState } from 'react';
import {
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import { postRequest } from 'backendServices/ApiCalls';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { useNavigate } from 'react-router-dom';

const iconMapping = {
    1: "/icon/bakery.svg",
    2: "/icon/makeup.svg",
    3: "/icon/bags.svg",
    4: "/icon/clothing.svg",
    5: "/icon/furniture.svg",
    6: "/icon/dailyneeds.svg",
    7: "/icon/books.svg",
    8: "/icon/electronics.svg",
    9: "/icon/medicine.svg",
    10: "/icon/home.svg",
};

export default function AddCategory() {
    const nav = useNavigate();
    const [category, setCategory] = useState('');
    const [icon, setIcon] = useState('');
    const [image, setImage] = useState(null);
    const [bannerName, setBannerName] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [alertData, setalertData] = useState({ show: false, message: "", variant: "", });
    const [bannerProgress, setBannerProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleIconChange = (event) => {
        setIcon(event.target.value);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const img = new Image();

        img.onload = () => {
            if (img.width >= 1910 && img.width <= 1930 && img.height >= 620 && img.height <= 640){
                setImage(file);
                setImagePreview(URL.createObjectURL(file));

                let formData = new FormData();
                formData.append("image", file);
                setBannerProgress(true);

                postRequest("/uploadCategoryBanner", formData, (response) => {
                    setBannerProgress(false);
                    console.log('response?.data?.data', response?.data?.data)
                    if (response?.data?.status === "success") {
                        setBannerName(response?.data?.data)
                        setalertData({
                            show: true,
                            message: 'Image uploaded successfully',
                            variant: 'success'
                        });
                    } else {
                        setalertData({
                            show: true,
                            message: 'Image not uploaded successfully',
                            variant: 'error'
                        });
                    }
                },
                    (error) => {
                        setBannerProgress(false);
                        setalertData({
                            show: true,
                            message: 'Image not uploaded successfully',
                            variant: 'error'
                        });
                        console.log(error?.response?.data);
                    });

            } else {
                setalertData({
                    show: true,
                    message: 'Image dimensions must be 1920 x 630 pixels',
                    variant: 'error'
                });
            }
        };

        img.src = URL.createObjectURL(file);
    };

    const handleSubmit = () => {
        if (!category || !bannerName) {
            setalertData({
                show: true,
                message: 'Please fill all the fields and upload the image.',
                variant: 'error'
            });
            return;
        }
        let formData = {
            category: category,
            icon: icon,
            banner: bannerName
        }
        // console.log('formData', formData);
        setLoading(true);
        postRequest("/addCategoryInfo", formData, (response) => {
            console.log('response?.data?.data', response?.data?.data)
            if (response?.data?.status === "success") {
                setCategory('');
                setIcon('');
                setBannerName('');
                setImage(null);
                setImagePreview('')
                setalertData({
                    show: true,
                    message: 'Category info added successfully',
                    variant: 'success'
                });
                nav('/marketPlaceManageCategory')
            } else {
                setalertData({
                    show: true,
                    message: 'Category not info added',
                    variant: 'error'
                });
            }
            setLoading(false);
        },
            (error) => {
                setLoading(false);
                setalertData({
                    show: true,
                    message: 'Service error',
                    variant: 'error'
                });
                console.log(error?.response?.data);
            });
    };

    return (
        <JumboDemoCard title={"Add Category"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            <Box display="flex" width={'100%'} flexDirection="column" gap={2}>
                {alertData.show && (
                    <SweetAlert alertData={alertData} setalertData={setalertData} />
                )}

                <Box display="flex" gap={2}>
                    <TextField
                        label="Category"
                        variant="outlined"
                        value={category}
                        onChange={handleCategoryChange}
                        sx={{ flex: 1 }}
                    />

                    {/* <FormControl variant="outlined" sx={{ flex: 1 }}>
                        <InputLabel id="icon-select-label">Icon</InputLabel>
                        <Select
                            labelId="icon-select-label"
                            value={icon}
                            onChange={handleIconChange}
                            label="Icon"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {Object.keys(iconMapping).map((key) => {
                                let nameOfIcon = iconMapping[key].replace(/^\/icon\/(.+)\.svg$/, (_, p1) => p1.charAt(0).toUpperCase() + p1.slice(1));
                                return (
                                    <MenuItem key={key} value={nameOfIcon}>
                                        <img src={iconMapping[key]} alt={`Icon ${key}`} style={{ width: 24, marginRight: 8 }} />
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl> */}
                </Box>

                {/* <TextField
                    label="Image dimensions must be 1920 x 630 pixels"
                    variant="outlined"
                    sx={{ flex: 1 }}
                /> */}
                <Div>Image dimensions must be 1920 x 630 pixels</Div>
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                >
                    Upload Image
                    <input
                        type="file"
                        hidden
                        onChange={handleImageUpload}
                        disabled={bannerProgress}
                    />
                </Button>

                {imagePreview && (
                    <Box mt={2} position="relative" display="inline-block">
                        <Typography>Image Preview:</Typography>
                        <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                        {bannerProgress && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                                    zIndex: 1
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={bannerProgress}
                >
                    Submit
                </Button>
            </Box>
        </JumboDemoCard>
    );
}
