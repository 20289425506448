import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Box, Button, CircularProgress, Modal } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import ImageIcon from '@mui/icons-material/Image';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import UpdateProduct from './UpdateProduct';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlertConfirm from './SweetAlertConfirm';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import { useNavigate } from 'react-router-dom';

export default function StoreProduct() {
    const nav = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [link, setLink] = useState('')
    const [imageSrc, setImageSrc] = useState([]);
    // const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState({});
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateProductData, setUpdateProductData] = useState({});
    const [alertData, setalertData] = useState({ show: false, message: "", variant: "", });
    const [confirmDelete, setConfirmDelete] = useState({ show: false, id: null, processing: false });

    const SellerData = () => {
        setLoading(true);
        postRequest("/productData", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index + 1,
                    // productId: row?.id,
                    updateProduct: row?.id,
                    deleteProduct: row?.id,
                    username: row?.username || "",
                    price: row?.price || "",
                    quantity: row?.quantity || "",
                    // weight: row?.weight || "",
                    store_name: row?.store_name || "",
                    title: row?.title.replace(/\\/g, '') || "",
                    description: row?.description.replace(/\\/g, '') || "",
                    category: row?.category_name || "",
                    categoryId: row?.category || "",
                    picture: row?.picture || "",
                    action: row?.picture || "",
                    weight: row?.weight || "",
                }));

                setData(formattedData);
                setLink(response?.data?.link);
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        SellerData()
    }, [])

    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80, },
        {
            field: "picture", headerName: "Images", width: 150,
            renderCell: (params) => (
                <div>
                    <img src={link + JSON.parse(params.value).imageNames[0]} style={{cursor:'pointer', width:'30%'}} alt="" />
                    {/* <ImageIcon style={{ cursor: 'pointer' }} onClick={() => handleImageClick(JSON.parse(params.value).imageNames.map(name => link + name))} /> */}
                </div>
            )
        },
        // { field: "productId", headerName: "Product Id", width: 100, },
        { field: "title", headerName: "Title", width: 150, },
        {
            field: "description", headerName: "Description", width: 150,
            renderCell: (params) => (<div dangerouslySetInnerHTML={{ __html: params.value }} />)
        },
        { field: "category", headerName: "Category", width: 150, align: "center" },
        { field: "store_name", headerName: "Store Name", width: 150, },
        { field: "username", headerName: "Username", width: 150, },
        { field: "action", headerName: "Action", width: 150, renderCell: (params) => (<VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => handleImageClick(JSON.parse(params.value).imageNames.map(name => link + name),params.row)} />) },
        { field: "updateProduct", headerName: "Update", width: 150, renderCell: (params) => (<CreateIcon style={{ cursor: 'pointer' }} onClick={()=>handleProductUpdate(params.row)} />) },
        {
            field: "deleteProduct", headerName: "Delete", width: 150,
            renderCell: (params) => (
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(params.value)} />
            )
        },
        
    ];

    const handleDelete = (id) => {
        // setConfirmDelete(prev => ({ ...prev, processing: true }));
        setConfirmDelete({ show: true, id, processing: true });
        postRequest("/deleteadminproduct", { id }, async (response) => {
            if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: 'Product deleted successfully',
                    variant: 'success'
                });
                // SellerData();  // Refresh data
                setData(prevData => prevData.filter(item => item.deleteProduct !== id));
            } else {
                setalertData({
                    show: true,
                    message: 'Product not deleted',
                    variant: 'error'
                });
            }
            setConfirmDelete({ show: false, id: null, processing: false });
        }, (error) => {
            console.log(error?.response?.data);
            setConfirmDelete({ show: false, id: null, processing: false });
        });
    };
    

    const handleDeleteClick = (id) => {
        setConfirmDelete({ show: true, id, processing: false });
    };

    const confirmDeleteAction = (confirm) => {
        if (confirm) {
            handleDelete(confirmDelete.id);
        }else{
            setConfirmDelete({ show: false, id: null, processing: false });
        }
    };
    const handleImageClick = (imageUrlArray, description) => {
        setImageSrc(imageUrlArray);
        setModalDescription(description);
        setModalOpen(true);
    };
    const handleProductUpdate = (productData) => {
        setUpdateProductData(productData);
        setUpdateModalOpen(true);
    };

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    title: false,
                    description: false,
                    category: false,
                    store_name: false,
                    username: false,
                },
            },
        },
    };

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '400px'
    }

    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    return (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={()=>{nav("/marketPlaceAddProduct")}}>Add Product</Button>
                <Button variant="contained" color="primary" onClick={()=>{nav("/marketPlaceReferralProducts")}}>Product Requests</Button>
            </Box>
        <JumboDemoCard title={"Products"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            {confirmDelete.show && (
                <SweetAlertConfirm
                    alertData={{
                        show: true,
                        title: "Confirm Delete",
                        message: confirmDelete.processing ? "Deleting..." : "Are you sure you want to delete this category?",
                        variant: "warning",
                        processing: confirmDelete.processing,
                        actions: confirmDelete.processing ? [] : [
                            { label: "Cancel", action: () => confirmDeleteAction(false) },
                            { label: "Delete", action: () => confirmDeleteAction(true), color: "error" }
                        ]
                    }}
                    setAlertData={setConfirmDelete}
                />
            )}
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height:'70%',
                        overflow:'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <h4><span style={{fontWeight :'bold'}}>Store Name</span>: {modalDescription.store_name}</h4>
                    <h4><span style={{fontWeight :'bold'}}>Category</span>: {modalDescription.category}</h4>
                    <h4><span style={{fontWeight :'bold'}}>Weight</span>: {modalDescription.weight} pound</h4>
                    <h4><span style={{fontWeight :'bold'}}>Title</span>: {modalDescription.title}</h4>
                    <h4 style={{fontWeight :'bold'}}>Description:</h4>
                    <div dangerouslySetInnerHTML={{ __html: modalDescription.description }} />
                    <div className="slide-container">
                        <Slide>
                            {imageSrc.map((slideImage, index) => (
                                <div key={index} style={divStyle}>
                                    <img src={slideImage} alt={`Image ${index + 1}`} style={{maxWidth: '100%',maxHeight: '100%',objectFit: 'contain'}} />
                                </div>
                            ))}
                        </Slide>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                aria-labelledby="update-modal-title"
                aria-describedby="update-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height:'70%',
                        overflow:'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <UpdateProduct updateProductData={updateProductData} SellerData={SellerData} link={link} setUpdateModalOpen={setUpdateModalOpen} />
                </Box>
            </Modal>
        </JumboDemoCard>
        </>
    )
}
