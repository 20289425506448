import React, { createContext, useContext, useEffect, useState } from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import { headerTheme as theme4 } from "../../themes/header/theme4";
import { headerTheme as defaultTheme } from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";
import { AppContext } from "app/AppContext";
import { authUserData, postRequest } from "backendServices/ApiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { ASSET_IMAGES } from "app/utils/constants/paths";

const CustomProvider = createContext();

const VerticalDefault = ({ children }) => {

  const { setJumboLayoutOptions } = useJumboLayout();
  const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
  const { theme } = useJumboTheme();
  const appBarBgColor =
    headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
  const { sidebarOptions } = useJumboLayoutSidebar();
  const appData = useApp();
  const [cartItems, setCartItems] = useState([]);
  const [loginUserData, setloginUserData] = useState("");
  const [loginUserToken, setloginUserToken] = useState("");
  const [loading, setLoading] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const location = useLocation();
  const pathname = location.pathname;
  // Split the pathname by '/' to get an array of segments
  const pathnameSegments = pathname.split("/");
  // Find and extract the first segment (the route name)
  const currentRouteName = pathnameSegments[1]; // Assuming that the route name is always the second segment

  const nav = useNavigate();
  const getUserData = async () => {
    await setLoading(true);
    await authUserData(
      async (response) => {
        await setloginUserData(response?.data?.data);
        await setloginUserToken(response?.data?.data?.decodedToken);
        await setLoading(false);
      },
      async (error) => {
        console.log(error?.response?.data);
        await setLoading(false);
        if (
          currentRouteName !== "login" &&
          currentRouteName !== "2fa" &&
          currentRouteName !== "signup" &&
          currentRouteName !== "logout" &&
          currentRouteName !== "forget-password" &&
          currentRouteName !== "reset-password" &&
          currentRouteName !== "plans" &&
          currentRouteName !== "check-out" &&
          currentRouteName !== "redirect"
        ) {
          await setalertData({
            show: false,
            message: "session expired please login again",
            variant: "error",
          });
          await nav("/logout");
        }
      }
    );
  };

  useEffect(async () => {
    await getUserData();
  }, [nav]);
  // const {...authOptions} = useJumboAuth()
  // const loginUserData = authOptions




  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setalertData({
      show: false,
      message: "",
      variant: "",
    });
  };

  React.useEffect(() => {
    setJumboLayoutOptions(layoutConfig);
  }, []);

  const { checkLogin } = useContext(AppContext);
  let LoginStatus;

  if (
    loginUserToken?.two_factors == "enable" &&
    loginUserToken?.twofactorsverified == "no"
  ) {
    LoginStatus = false;
  } else {
    LoginStatus = checkLogin();
  }

  React.useEffect(() => {
    if (
      appBarBgColor === "#F5F7FA" &&
      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
    ) {
      setHeaderTheme({ ...theme, ...theme4 });
      appData.setAppState({
        prevHeaderBgColor: theme?.mode === "dark" ? "#1F1F1F" : "#F5F7FA",
      });
    } else if (
      appData.prevHeaderBgColor &&
      appData.prevHeaderBgColor === "#F5F7FA"
    ) {
      setHeaderTheme({ ...theme, ...defaultTheme });
    }
  }, [sidebarOptions.style]);

  return (
    <CustomProvider.Provider
      value={{
        isLoading,
        setIsLoading,
        loginUserData,
        setloginUserData,
        loading,
        open,
        setOpen,
        handleOpen,
        handleClose,
        alertData,
        setalertData,
      }}
    >

      <JumboLayout
        header={LoginStatus && <Header />}
        sidebar={LoginStatus && <Sidebar />}
        footer={LoginStatus && <Footer loginUserData={loginUserData} />}
        headerSx={{
          height: 80,
        }}
      >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
        {loading ? (
          <Div
          sx={{
            display: "flex",
            minWidth: 0,
            alignItems: "center",
            alignContent: "center",
            height: "100%",
            justifyContent: "center", // Align items horizontally
            textAlign: 'center'
          }}
        >
          <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

        </Div>
        ) : (
          children
        )}
      </JumboLayout>
    </CustomProvider.Provider>
  );
};

export default VerticalDefault;
export { CustomProvider };