import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getpackagesdata, postRequest, updatepackages } from "backendServices/ApiCalls";
import { Grid, List, Modal, Typography } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import EditIcon from "@mui/icons-material/Edit";
import Div from "@jumbo/shared/Div";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  ranks_name: yup.string().required("Ranks Name is required"),
  lesser_leg_Points: yup.number().required("Lesser Leg Points is required"),
  incentives: yup.string().required("Incentives is required"),
  cash: yup.number().required("Cash is required"),
  pool_bonus: yup.number().required("Pool Bonus is required"),
});

function Ranks() {
  const [ranksReport, setRanksReport] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetRanksData = () => {

    postRequest(
      "/getranksdata",
      '',
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
					setRanksReport(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };


  useEffect(() => {
    GetRanksData();
  }, []);

	const columns = [
    // {
    //   field: "index",
    //   headerName: "#",
    //   width: 80,
    //   valueGetter: (params) => params.row.id,
    // },
    {
      field: "ranks_name",
      headerName: "Ranks Name",
      width: 200,
      
      groupable: false,
    },
    {
      field: "lesser_leg_Points",
      headerName: "Lesser Leg Points",
      width: 200,
      
      groupable: false,
    },
    {
      field: "incentives",
      headerName: "Incentives",
      width: 200,
      
      groupable: false,
    },
    {
      field: "cash",
      headerName: "Cash",
      width: 200,
      
      groupable: false,
			renderCell: (params) => (
				<div>
					${params.row.cash}
				</div>
			)
    },
    {
      field: "pool_bonus",
      headerName: "Global Pool Bonus",
      width: 200,
      
      groupable: false,
			renderCell: (params) => (
				<div>
					{params.row.pool_bonus}%
				</div>
			)
    },
    {
      field: "action",
      headerName: "Action",
      width: 10,
      renderCell: (params) => (
        <>
          <EditIcon
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(params.row.id)}
          />
        </>
      ),
    },
  ];

  const handleOpen = (id) => {
    const rowToEdit = ranksReport.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
  };

	const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

	const handleClose = () => setOpen(false);

  const handleUpdate = (data, setSubmitting, resetForm) => {
    const additionalData = {
      id: rowData.id,
    };

    const newData = { ...data, ...additionalData };

    postRequest(
      "/updateranks",
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
          GetRanksData();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Packages Report"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
				<Div
				sx={{
					mt:"20%",
					ml:"45%",
					mb: "20%"
				}}
			>
				<CircularProgress />
			</Div>
		):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={ranksReport}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          {/* Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update Ranks
              </Typography>
              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                {/* Display the image if it's available in rowData */}
                {rowData?.imageURL && (
                  <div>
                    <img
                      src={rowData.imageURL}
                      alt={`Product ${rowData.title}`}
                      style={{ width: "25%", display: "flex", margin: "auto" }} // Adjust the style as needed
                    />
                  </div>
                )}
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    ranks_name: rowData?.ranks_name || "",
                    lesser_leg_Points: rowData?.lesser_leg_Points || "",
                    incentives: rowData?.incentives || "",
                    cash: rowData?.cash || "0",
                    pool_bonus: rowData?.pool_bonus || "0",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleUpdate(data, setSubmitting, resetForm);
                    handleClose();
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="ranks_name"
                              label="Ranks Name"
                              type="text"
                              inputPropes={{ 
																readOnly: true 	
															}}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="lesser_leg_Points"
                              label="Lesser Leg Points"
                              type="number"
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="incentives"
                              label="Incentives"
                              type="text"
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="cash"
                              label="Cash"
                              type="number"
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="pool_bonus"
                              label="Pool Bonus"
                              type="number"
                            />
                          </Div>
                        </Grid>
                      </Grid>
                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
          {/* Modal */}
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default Ranks;
