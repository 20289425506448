import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ChatBox from './ChatBox';
import { Chip, CircularProgress } from '@mui/material';

const Inbox = () => {
  const [messages, setMessagesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [loadingRows, setLoadingRows] = useState({});

  const messagesData =()=>{
    let params = {
        type:'receiver',
        userid:"admin"
  }
  postRequest('/getmessages',params,(response) => {
          setMessagesData(response?.data?.data)
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
    
useEffect(()=>{
    messagesData();
},[])


const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
      }
  }
}
}




const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
                color: '#8cda24',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}
      // Otherwise filter will be applied on fields such as the hidden column id
      const columns = [
       
        {
          field: "senderusername",
          headerName: "Sender",  
          dataGeneratorUniquenessEnabled: true,
          width: 150,
          
          groupable: false,
          aggregable: false,
      },
      {
        field: "title",
        headerName: "Title",  
        dataGeneratorUniquenessEnabled: true,
        width: 400,
        
        groupable: false,
        aggregable: false,
     },


     {
      field: "mstatus",
      headerName: `Status`,
      width: 200,
      renderCell: (params) => (
        <Chip label={params.value} color={params.value === "seen" ? 'warning' : 'success'} size='small' />
      ),
    },
        {
            field: "createdat",
            headerName: 'Date',
            width: 200,
            renderCell:(params) => {
             return  format(new Date(params.row.createdat), 'MM/dd/yyyy');

            }
        },
        {
          field: "actions",
          headerName: "Actions",
          width: 100,
          renderCell: (params) => (
            <IconButton
              onClick={() => handleViewClick(params.row)}
              color="primary"
              disabled={loadingRows[params.row.id]} // Disable the button when loading
            >
              {loadingRows[params.row.id] ? <CircularProgress size={24} /> : <ViewIcon />}
            </IconButton>
          ),
        },

    ]
    const handleViewClick = async (row) => {
      await setLoadingRows(prevLoadingRows => ({
        ...prevLoadingRows,
        [row.id]: true, // Assuming you have a unique identifier like 'id' for each row
      }));

      await  postRequest(
        "/updatemessage",
        {msgId:row?.id},
        (response) => {},(error) => {
          console.log(error?.response?.data);
        }
      );
      await setSelectedMessage(row);
      await setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
    };
    const rows= messages

  return (
    <JumboDemoCard
    title={"Inbox Message"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>
    <Box sx={{ height: 460, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      <Dialog open={dialogOpen} sx={{maxHeight:'700px'}} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogContent>
          <ChatBox selectedMessage={selectedMessage} setLoadingRows={setLoadingRows} />
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Box>
  </JumboDemoCard>
  )
}

export default Inbox