import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, FormControlLabel, Grid, List, ListItem, Typography } from "@mui/material";
import { useState } from 'react';
import { getsettingsdata, managepopup, updatesettingdata } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import Switch from "@mui/material/Switch";
import { CKEditor } from "ckeditor4-react"; // Import CKEditor

const validationSchema = yup.object({
    popup_status: yup
        .string()
        .required('status is required'),
    description: yup
        .string()
        .required('Description is required')
});

const Managepopup = () => {
    const [popupdata, setPopupData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "black"
            }
        }
    }

    const fetchPopupData = () => {
        const params = {
            keynames:
                "'popup_status','popup_detail'",
        };
        getsettingsdata(params, (response) => {
            setPopupData(response?.data?.data?.values);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchPopupData();
    }, []);

    const handleSubmit = (data, setSubmitting, resetForm) => {
        const newData = {
            obj: {
                popup_status: data.popup_status,
                popup_detail: data.description,

            },
        };
        updatesettingdata(
            newData,
            (response) => {
                if (response?.data?.status === "error") {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: "error",
                    });
                    setSubmitting(false);
                } else if (response?.data?.status === "success") {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: "success",
                    });
                    setSubmitting(false);
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    };

    if (isLoading) {
        return ('Loading...')
    }

    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={6} xs={12}>
                <JumboCardQuick title={"Manage Popup"} noWrapper>
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding sx={{ mb: 2 }}>
                        <Formik
                            enableReinitialize={true} // Add enableReinitialize prop
                            validateOnChange={true}
                            initialValues={{
                                popup_status: popupdata[0]?.keyvalue || "",
                                description: popupdata[1]?.keyvalue || "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting, values, setFieldValue }) => (
                                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Typography sx={{ pt: 1, pl: 1 }}>
                                                    Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={values.popup_status === "On"} // Check if referral_commission_status is 'On'
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "popup_status",
                                                                    e.target.checked ? "On" : "Off"
                                                                )
                                                            } // Set 'On' or 'Off' based on the switch state
                                                            name="popup_status"
                                                            color="primary"
                                                            value={values.popup_status} // Set value as 'On' or 'Off'
                                                        />
                                                    }
                                                    label={values.popup_status} // Display 'On' or 'Off' based on the switch state
                                                />
                                            </Grid>
                                        </Grid>

                                    </Div>

                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        {/* Replace the textarea with CKEditor */}
                                        <CKEditor
                                            initData={popupdata[1]?.keyvalue}
                                            onChange={(event) => setFieldValue("description", event.editor.getData())}                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Managepopup;
