import axios from "axios";

const API_BASE_URL = "https://mptestbackend.m5networkhub.com";
// const API_BASE_URL = "http://localhost:8000";

function updateAuthorizationHeader() {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["authorization"] = "Bearer " + token;
}

//react admin routes

export function authUserData(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/userdata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update withdrawal get api getsettingsdata

export function getsettingsdata(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getsettingsdata"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function uploadProduct(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/admin/api/uploadproduct"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get Manage all product
export function getproduct(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/healiving/api/getproduct"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteproduct(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/deleteproduct", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Update Product
export function updateproduct(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updateproduct", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Update Packages
export function updatepackages(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatepackages", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Update Packages
export function updatestockistsdata(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatestockistsdata", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function updatesettingdata(data, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/admin/api/updatesettingsdata"}`, data)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function dashboard(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/dashboard"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// dashboard transactions
export function dashboardtransactions(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/lasttransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//donation summary
export function donationsummary(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/donationsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//payout summary

export function payoutsummary(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/payoutsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deployuser(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/deployuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function updatetransactionpassword(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/updatetransactionpassword"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addnews(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/addnews"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addvideo(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/addvideo"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function getvideos(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getvideos"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function deletevideo(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/deletevideo", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function managepopup(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/managepopup"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deploymanualpaymentuser(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/deploymanualpaymentuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function rejectmanualpaymentuser(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/rejectmanualpaymentuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Get Users List

export function getusers(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/getuserslist", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//get manual payments data

export function getmanualpayments(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/getmanualpayments", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Delete Pending user

export function deleteuser(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/admin/api/deleteuser", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//Create Admin

export function createadmin(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/admin/api/createadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Get Admins

export function getadmins(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getadminslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//getminiadmin

export function GetMiniAdminApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/admin/api/getminiadmin"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// DeleteMiniAdminApi

export function DeleteMiniAdminApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/admin/api/deleteminiadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


// get getuserslist api

export function getuserslist(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getuserslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get Manage all news

export function getnews(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getnews"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get KYC Pending

export function getkycreport(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/kycreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get payout details update report

export function GetPayoutDetailsUpdateReport(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/payoutdetailsupdatereport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get payout details update report
export function SubScriptionReportApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/admin/api/subscriptionreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get packages report
export function getpackagesdata(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/admin/api/getpackagesdata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// affilate report

export function AffilateReportApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/affilatereport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// payout report

export function PayoutReportApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/payoutreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// get users list

export function GetUsersListApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getuserslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// rank report

export function RankReportApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/rankreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// pending payout

export function PendingPayoutApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/pendingpayout"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//Remove  Mini Admin
export function removeadmin(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/removeadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approvesinglepayout
export function ApproveSinglePayoutApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/approvesinglepayout", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approveall payout
export function ApproveAllPayoutApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/approveallpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//approved payout
export function ApprovedPayoutApi(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/approvedpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//updatepassword
export function UpdatepasswordApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatepassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update transaction password
export function UpdatetransactionPasswordApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatetransactionpassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update user password
export function UpdateUserPasswordApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updateuserpassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approvekyc
export function ApprovekycApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/approvekyc", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approve payout update request
export function ApprovePayoutUpdateRequestApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/approvepayoutupdaterequest", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//rejectkyc
export function RejectkycApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/rejectkyc", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//reject payout update request
export function RejectPayoutUpdateRequestApi(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/rejectpayoutupdaterequest", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// delete news api

export function deletenews(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/deletenews", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// Update the User Balance

export function UpdateCurrentBalance(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatecurrentbalance", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Update Sponsor
export function updatesponsor(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/updatesponsor", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function registerUser(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/user/register", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL, service)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function roidata(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/user/roidata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function lasttransactions(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/lastweektransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralusers(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/user/referralusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investmentreport(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/depositsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralbonusreport(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/referralbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function unilevelbonusreport(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/unilevelbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function faqdata(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getfaqs"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function transaction(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/transaction", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function invitation(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(API_BASE_URL + "/admin/api/sendinvitationlink", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function adminwallet(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getadminwallet"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getHierarchyData(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/gethierarchy"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getBinaryTreeData(userrandomcode, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getbinarytree"}`, {
      userrandomcode: userrandomcode,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfileData(formdata, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/updateprofiledata"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function verifyUserEmailManual(params, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/verifyuseremailmanual"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePicture(formdata, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/updateprofilepicture"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePassword(
  oldpassword,
  newpassword,
  callback,
  errorCallback
) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/updatepassword"}`, {
      oldpassword,
      newpassword,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function requestPasswordReset(email, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/forgetpassword"}`, {
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investandeearning(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/monthlyinvestandeearning"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnotifications(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/getnotifications"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatenotificationstatus(callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/updatenotificationstatus"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function passwordReset(email, password, callback, errorCallback) {
  updateAuthorizationHeader();

  axios
    .post(`${API_BASE_URL}${"/admin/api/resetpassword"}`, {
      email,
      password,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function validateEmailToken(token, email, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/admin/api/validateemailtoken"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function verifyemailaccount(token, email, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/admin/api/verifyemailaccount"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//za apicall of orderstatus

export function getorderstatuslist(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/getorderlist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total products count api

export function gettotalproducts(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/admin/healiving/api/gettotalproducts"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total pending orders api

export function getpendingorders(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/admin/healiving/api/getpendingorders"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total active users

export function getactiveusers(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/getactiveusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total inactive users

export function getinactiveusers(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/getinactiveusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total users

export function getallusers(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/getallusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// total reffral bonus

export function getreffrelbonus(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/getreffrelbonus"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function postRequest(url, params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/admin/api${url}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function postMethod(url, params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${url}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function dashboardAPI(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"admin/api/dashboardData"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
