import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, List } from "@mui/material";
import { useState } from 'react';
import { addnews} from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { CKEditor } from 'ckeditor4-react';


const validationSchema = yup.object({
    title: yup
        .string()
        .required('Title is required'),
    description: yup
        .string()
        .required('Description is required')
});


const Addnews = () => {

    const [image, setImage] = useState(null);
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "black"
            }
        }
    }

    const handleUpload = (e) => {
        if (e.target.files && e.target.files.length > 0) {
           const selectedFile = e.target.files[0];
            if (selectedFile.type.startsWith("image/")) {
                setImage(selectedFile);
              } else {
                setalertData({
                    show:true,
                    message:'Invalid file type. Please select an image file.',
                    variant:"error"
                })
              }
        }
    }

    const handleSubmit = (data, setSubmitting, resetForm) => {
        const reader = new FileReader();
        reader.onloadend = () => {

            const base64Data = reader.result; // Extract base64 encoded string
            const formData = new FormData();
            formData.append('image', base64Data);
            formData.append('title', data.title);
            formData.append('description', data.description);

        addnews(formData, (response) => {
            console.log(response);
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error"
                })
                setSubmitting(false)
            }
            else if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                })
                setSubmitting(false);
                resetForm();
            }
            else {
                setalertData({
                    show: true,
                    message: 'Something went wrong please try again later',
                    variant: "error"
                })
                setSubmitting(false);
            }
        }, (error) => {
            console.log(error?.response?.data);
        });
    };
  
    reader.readAsDataURL(image);
  
    }
    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={6} xs={12}>
                <JumboCardQuick title={"Add News"} noWrapper>
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding sx={{ mb: 2 }}>
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                title: '',
                                description: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting,setFieldValue,values }) => (
                                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="title"
                                            label="Title"
                                            type="text"
                                        />
                                    </Div>

                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            onInput={(e)=>handleUpload(e)}
                                            name='file'
                                            type='file'
                                            label=' Image'
                                            margin="normal"
                                            InputProps={{endAdornment: 
                                                <CameraAltIcon fontSize={"small"} color={"success"}/>
                                            }}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                    
                                            />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
 
                                    <CKEditor
        initData={values.description}
          onChange={(event) => setFieldValue("description", event.editor.getData())}
        />
                                    </Div>
                                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Addnews;
