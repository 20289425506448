import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'

const BinaryPoints = ({userid}) => {

    const [reportData, setReportData] = useState([])

    const ReportData = () => {
        postRequest(
          '/weeklybinaryreport',
          {userid:userid},
          (response) => {
            if (response?.data?.status === 'success') {
              setReportData(response?.data?.data);
            }
          },
          (error) => {
            console.log(error?.response?.data);
          }
        );
      };
    
      useEffect(() => {
        ReportData();
      }, []);

      let idCounter = 1;

      const rowsWithId = reportData.map((row) => ({
        ...row,
        id: idCounter++,
      }));

const formatDate =  (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  return (
    <JumboDemoCard title={'Binary Points'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

<TableContainer component={Paper} sx={{maxHeight:'300px', overflowY:'scroll'}}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sender</TableCell>
            <TableCell>Points (Leg)</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsWithId.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.senderusername}
              </TableCell>
              <TableCell align="right">{`${row.points} (${row.leg === 'L' ? 'Left' : 'Right'})`}</TableCell>
              <TableCell align="right">{formatDate(row.createdat)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </JumboDemoCard>
  )
}

export default BinaryPoints