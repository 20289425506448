import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { Chip } from '@mui/material';
import { format } from 'date-fns';


const initialState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
      }
    }
  }
}


const ApprovedAchievers = () => {

  const [payoutdata, setPayoutData] = useState([])

  const PayoutData = () => {
    postRequest('/rankachievers', { type: 'approved' }, (response) => {
      if (response?.data?.status === "success") {
        setPayoutData(response?.data?.data)
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    PayoutData();
  }, [])



  const columns = [
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "ranks_name",
      headerName: "Rank",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "reward",
      headerName: "Rank Reward",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === 'pending' ? (<Chip color='warning' size='small' label='Pending' />)
          :
          params.value === 'rejected' ? (<Chip color='error' size='small' label='Rejected' />)
            :
            (<Chip color='success' size='small' label='Approved' />)
      }
    },
    {
      field: "updatedat",
      headerName: "Approved At",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      renderCell: (params) => {
        return format(new Date(params.value), 'dd-MM-yyyy');
      }
    },

  ]

  const rows = payoutdata
  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#8cda24',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }

  return (
    <JumboDemoCard
      title={"Approved Rank Achievers"}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default ApprovedAchievers