import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { Button } from '@mui/material';


const VISIBLE_FIELDS = ['sr', 'senderusername', 'amount', 'payoutmethod', 'payoutaccount1', 'payoutaccount2', 'createdat', 'hash', 'status'];

const ApprovedDeposit = () => {
  const [usersData, setUsersData] = useState([]);
  const [imageUrl, setimageUrl] = useState('');


  let params = {
    status: 'approved',
    type: 'deposit',
    usertype: 'sender'
  };


  const UsersData = () => {
    postRequest(
      '/selecttransactions',
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          setUsersData(response?.data?.data);
          setimageUrl(response?.data?.backendUrl)
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };



  const rows = usersData;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#8cda24',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Approved Deposit'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => row.id}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'senderusername') {
                return {
                  field,
                  headerName: 'Username',
                  width: 150,
                };
              }
              else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 150,
                  renderCell: (params) => <strong>{'$' + params.row.amount}</strong>,

                };
              }
              else if (field === 'payoutmethod') {
                return {
                  field,
                  headerName: 'Payout Method',
                  width: 150,
                };
              } else if (field === 'payoutaccount1') {
                return {
                  field,
                  headerName: 'Account',
                  width: 150,
                };
              }
              else if (field === 'payoutaccount2') {
                return {
                  field,
                  headerName: 'Screenshot',
                  width: 100,
                  renderCell: (params) => {
                    const handleOpenImageInNewTab = () => {
                      // Get the image URL from the cell data
                      const name = params.value;

                      // Open the image in a new tab
                      window.open(imageUrl + name, '_blank');
                    };
                    return (
                      <div>
                        <Button onClick={handleOpenImageInNewTab} variant='contained' size='small'>View</Button>
                      </div>
                    );
                  }
                }
              }
              else if (field === 'hash') {
                return {
                  field,
                  headerName: 'Transaction ID',
                  width: 150,
                };
              }
              else if (field === 'status') {
                return {
                  field,
                  headerName: 'Status',
                  width: 150,
                };
              }
              else if (field === 'createdat') {
                return {
                  field,
                  headerName: 'Date',
                  width: 150,
                };
              }

              return {
                field,
                headerName: field,
                width: 150,
              };;
            }),
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default ApprovedDeposit;
