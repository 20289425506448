import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Box, CircularProgress, Grid, Modal, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function StoreOrder() {
    const [data, setData] = useState([])
    const [orderData, setOrderData] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false)

    const SellerData = () => {
        setLoading(true);
        postRequest("/orderinfo", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index + 1,
                    orderId: row?.orderId || "",
                    amount: row?.amount || "",
                    paymentType: row?.paymentType || "",
                    status: row?.status || "",
                    action: row?.orderId || "",
                }));

                setData(formattedData);
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        SellerData()
    }, [])

    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80 },
        // { field: "orderId", headerName: "Order Id", width: 150, renderCell: (params) => (<div>{params.value}</div>) },
        { field: "orderId", headerName: "Order Id", width: 150 },
        { field: "amount", headerName: "Amount", width: 150 },
        { field: "paymentType", headerName: "Payment Type", width: 150 },
        { field: "status", headerName: "Status", width: 150 },
        { field: "action", headerName: "Action", width: 150, renderCell: (params) => (<VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => OrderDetail(params.value)} />) },
    ];

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    orderId: false,
                    amount: false,
                    paymentType: false,
                },
            },
        },
    };

    const OrderDetail = (orderId) => {
        setModalOpen(true)
        let param = {
            orderId
        }
        setLoadingDetail(true);
        postRequest("/orderDetail", param, async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index + 1,
                    orderId: row?.orderId || "",
                    amount: row?.amount || "",
                    quantity: row?.productquantity || "",
                    size: row?.productsize || "",
                    store_name: row?.store_name || "",
                    title: row?.title || "",
                    productId: row?.productId || "",
                }));
                // console.log('formattedData', formattedData)
                setOrderData(formattedData);
                setLoadingDetail(false);
            }
            if (response?.data?.status === "error") {
                setLoadingDetail(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoadingDetail(false);
            }
        );
    };

    // const columnsOrderDetail = [
    //     { field: "id", headerName: "Sr#", width: 80 },
    //     // { field: "productId", headerName: "Product ID", width: 150 },
    //     { field: "title", headerName: "Product Title", width: 150 },
    //     { field: "store_name", headerName: "Store Name", width: 150 },
    //     { field: "amount", headerName: "Amount", width: 150 },
    //     { field: "quantity", headerName: "quantity", width: 150 },
    //     { field: "size", headerName: "Size", width: 150 },
    //     { field: "orderId", headerName: "Order Id", width: 150, renderCell: (params) => (<div style={{ cursor: 'pointer' }} onClick={() => OrderDetail(params.value)}>{params.value}</div>) },
    // ];

    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: 800,
        maxHeight: '80vh',
        overflowY: 'auto',
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: '20px'
    };

    const thStyle = {
        padding: '10px',
        textAlign: 'left'
    };

    const tdStyle = {
        padding: '10px'
    };
    return (
        <JumboDemoCard title={"Order Info"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">Product Detail</Typography>
                    {
                        loadingDetail ?
                            <Div sx={{ display: "flex", minWidth: 0, alignItems: "center", alignContent: "center", height: 250, }}>
                                <CircularProgress sx={{ m: "-40px auto 0" }} />
                            </Div>
                            :
                            <Grid container>
                                <Grid item sm={12}>
                                    <table style={tableStyle}>
                                        <thead>
                                            <tr>
                                                <th style={thStyle}>Sr#</th>
                                                <th style={thStyle}>Product Title</th>
                                                <th style={thStyle}>Store Name</th>
                                                <th style={thStyle}>Amount</th>
                                                <th style={thStyle}>Quantity</th>
                                                <th style={thStyle}>Size</th>
                                                {/* <th style={thStyle}>Order Id</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.map((row) => (
                                                <tr key={row.id}>
                                                    <td style={tdStyle}>{row.id}</td>
                                                    <td style={tdStyle}>{row.title}</td>
                                                    <td style={tdStyle}>{row.store_name}</td>
                                                    <td style={tdStyle}>{row.amount}</td>
                                                    <td style={tdStyle}>{row.quantity}</td>
                                                    <td style={tdStyle}>{row.size}</td>
                                                    {/* <td style={tdStyle}>{row.orderId}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {/* <DataGrid
                                        rows={orderData}
                                        columns={columnsOrderDetail}
                                        slots={{ toolbar: GridToolbar }}
                                        sx={gridDesign}
                                        pageSizeOptions={[15, 30, 75, 100]}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    /> */}
                                </Grid>
                            </Grid>
                    }
                </Box>
            </Modal>
        </JumboDemoCard>
    )
}
