import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Autocomplete, Box, CircularProgress, Grid, List, MenuItem, TextField } from "@mui/material";
import { useState } from 'react';
import { UpdateCurrentBalance, getuserslist } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';



const validationSchema = yup.object({
  userid: yup
    .mixed()
    .required('User Name is required'),
  type: yup
    .string()
    .required('Balance Type is required'),
  amount: yup
    .number()
    .required("Amount is required"),
    notes: yup
    .string()
    .required('Note is required'),
});

const Updateuserbalance = () => {
  const [userlist, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })

  const userslist = () => {
    getuserslist(response => {
      setUserList(response?.data?.userdata);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    userslist();
  }, []);

  const handleSubmit = (data, setSubmitting,resetForm) => {
    UpdateCurrentBalance(data, (response) => {

      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })
        setSubmitting(false)

      } else if (response?.data?.status === "success") {
        resetForm()
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success"
        })
        setSubmitting(false);
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  };


  return (
    <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Credit/Debit User Balance"} noWrapper >
          {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  userid: null,
                  type: '',
                  amount: '',
                  notes: '',
                }
                }
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting,resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting,resetForm);
                }}
              >
                {({ isSubmitting, setFieldValue,values}) => (
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
<Autocomplete
                        sx={{ width: "100%" }}
                        value={values.userid}
                        onChange={(event, newValue) => {
                          setFieldValue("userid", newValue);
                        }}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.username}
                        options={userlist}
                        getOptionSelected={(option, value) =>
                          option.userid === value.userid
                        }
                        renderInput={(params) => (
                          <TextField {...params} name="userid" label="Select User" />
                        )}
                      />

                    </Div>
                    {/* <Div sx={{  mb: 3, pl: 2, pr: 2 }}>
                      {userlist.map(value =>(
                        <>
                        {console.log("value", value)}
                          <p>Current Balance of Ahsan is 100 </p>
                          </>
                        ))}
                    </Div> */}

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="type"
                        label="Balance Type"
                        select
                        required               

                      >
                        <MenuItem value="Add">Credit (Add)</MenuItem>
                        <MenuItem value="Deduct">Debit (Deduct)</MenuItem>
                      </JumboTextField>
                    </Box>
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="amount"
                        label="Total Amount"
                        type="number"
                        required               

                      />
                    </Div>
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="notes"
                        label="Notes"
                        type="text"
                        multiline
                        rows={4} // You can adjust the number of rows as needed   
                        required               
                      />
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );

};

export default Updateuserbalance;
