import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  postRequest,
} from "backendServices/ApiCalls";
import {
  CircularProgress,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
function StockistUsers() {
  const [isMainLoading, setIsMainLoading] = useState(true);
  const [usersdata, setUsersData] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });


  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };



  const columns = [
    {
      field: "id",
      headerName: "Sr#",
      width: 50,
      
      groupable: false,
      aggregable: false,
      valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist
    },
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      
      groupable: false,
      aggregable: false,
    },

    {
      field: "firstname", // New column for Full Name
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      
      groupable: false,
      aggregable: false,
    },
		{
      field: "lastname", // New column for Full Name
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      
      groupable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      
      groupable: false,
      aggregable: false,
    },

    {
      field: "mobile",
      headerName: "Phone",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      
      groupable: false,
      aggregable: false,
    },

    {
      field: "address",
      headerName: "Address",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      
      groupable: false,
      aggregable: false,
    },

    {
      field: "city",
      headerName: "City",
      dataGeneratorUniquenessEnabled: true,
      width: 80,
      
      groupable: false,
      aggregable: false,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "country",
      headerName: "Country",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      
      groupable: false,
      aggregable: false,
    },
		{
      field: "storename",
      headerName: "Store Name",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      
      groupable: false,
      aggregable: false,
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const UsersData = () => {
    postRequest(
			'/getstockistusers',
      '',
      (response) => {
        console.log("userdata", response);
        if (response?.data?.status === "success") {
          setUsersData(response?.data?.data);
          setIsMainLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsMainLoading(false);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const rows = usersdata;

  let idCounter = 1; // Initialize the counter

  // Assign a unique sequential ID to each row
  const rowsWithId = rows?.map((row) => ({
    ...row,
    id: idCounter++, // Assign the current counter value and then increment it
    fullname: row?.firstname + " " + row?.lastname,
  }));
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (isMainLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }


  return (
    <JumboDemoCard
      title={"Active Users"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Box sx={{ height: 500, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => row.id}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[15, 30, 75, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
}

export default StockistUsers;
