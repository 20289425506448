import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Box, CircularProgress } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'

export default function Seller() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const SellerData = () => {
        setLoading(true);
        postRequest("/sellerData", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index+1,
                    username: row?.username || "",
                    store_name: row?.store_name || "",
                    total_products: row?.total_products || 0,
                    email: row?.email || "",
                    firstname: row?.firstname || "",
                    lastname: row?.lastname || "",
                }));
                setData(formattedData);
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        SellerData()
    }, [])


    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80 },
        { field: "username", headerName: "Username", width: 150 },
        { field: "store_name", headerName: "Store Name", width: 150, renderCell: (params) => (<div>{params.value == ""? "Not available":params.value}</div>) },
        { field: "total_products", headerName: "Total Products", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "firstname", headerName: "First Name", width: 150 },
        { field: "lastname", headerName: "Last Name", width: 150 },
    ];

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    username: false,
                    store_name: false,
                    total_products: false,
                    email: false,
                    firstname: false,
                    lastname: false,
                },
            },
        },
    };
    

    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    return (
        <JumboDemoCard title={"Seller"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>
        </JumboDemoCard>
    )
}
