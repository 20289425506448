// import * as React from "react";
// import SwipeableViews from "react-swipeable-views";
// import { useTheme } from "@mui/material/styles";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import Seller from "./Seller";
// import Store from "./Store";
// import StoreOrder from "./StoreOrder";
// import StoreProduct from "./StoreProduct";
// import { CircularProgress, Grid } from "@mui/material";
// import { ObjectCountRevenue } from "app/shared/metrics/ObjectCountCards";
// import { postRequest } from "backendServices/ApiCalls";
// import { ShoppingCartOutlined } from "@mui/icons-material";
// import Div from "@jumbo/shared/Div";
// import ListAltIcon from "@mui/icons-material/ListAlt";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import Category from "./Category";
// import Tranding from "./Tranding";
// import SellerTab from "./SellerTab";
// import AddProduct from "./AddProduct";
// import ReferralProduct from "./ReferralProduct";

// export default function MarketplaceDashboard() {
//   const theme = useTheme();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleChangeIndex = (index) => {
//     setValue(index);
//   };

//   const [dashboardData, setDashboardData] = React.useState(null);
//   const [loader, setLoader] = React.useState(false);

//   const dashboardApiFunction = () => {
//     postRequest(
//       "/marketplaceDashboard",
//       "",
//       async (response) => {
//         setLoader(true);
//         // console.log("response?.data", response?.data?.data);
//         if (response?.data?.status === "success") {
//           setDashboardData(response?.data?.data[0]);
//           setLoader(false);
//         }
//       },
//       (error) => {
//         console.log(error?.response?.data);
//         setLoader(false);
//       }
//     );
//   };

//   React.useEffect(() => {
//     dashboardApiFunction();
//   }, []);

//   if (loader) {
//     return (
//       <Div
//         sx={{
//           display: "flex",
//           minWidth: 0,
//           alignItems: "center",
//           alignContent: "center",
//           height: "100%",
//         }}
//       >
//         <CircularProgress sx={{ m: "-40px auto 0" }} />
//       </Div>
//     );
//   }

//   return (
//     <Box sx={{ bgcolor: "#FFFFFF" }}>
//       <AppBar position="static">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="secondary"
//           textColor="inherit"
//           variant="fullWidth"
//           aria-label="full width tabs example"
//         >
//           <Tab label="Dashboard" {...a11yProps(0)} />
//           <Tab label="Seller" {...a11yProps(1)} />
//           <Tab label="Store" {...a11yProps(2)} />
//           <Tab label="Orders" {...a11yProps(3)} />
//           <Tab label="Category" {...a11yProps(4)} />
//           <Tab label="Trending Category" {...a11yProps(5)} />
//           <Tab label="Add Product" {...a11yProps(6)} />
//           <Tab label="Manage Products" {...a11yProps(7)} />
//           <Tab label="Referral Products" {...a11yProps(8)} />
//         </Tabs>
//       </AppBar>
//       <SwipeableViews
//         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//         index={value}
//         onChangeIndex={handleChangeIndex}
//       >
//         <TabPanel value={value} index={0} dir={theme.direction}>
//           <Grid container spacing={3.75}>
//             <Grid item xs={12} sm={6} lg={3}>
//               <ObjectCountRevenue
//                 value={dashboardData?.total_seller || 0}
//                 title="Total Sellers"
//                 color="info.main"
//                 icon={<PeopleAltIcon fontSize="large" />}
//                 vertical={true}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} lg={3}>
//               <ObjectCountRevenue
//                 value={dashboardData?.total_shops || 0}
//                 title="Total Shops"
//                 color="error.main"
//                 icon={<ShoppingCartOutlined fontSize="large" />}
//                 vertical={true}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} lg={3}>
//               <ObjectCountRevenue
//                 value={dashboardData?.total_products || 0}
//                 title="Total Products"
//                 color="info.main"
//                 icon={<LocalShippingIcon fontSize="large" />}
//                 vertical={true}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} lg={3}>
//               <ObjectCountRevenue
//                 value={dashboardData?.total_orders || 0}
//                 title="Total Orders"
//                 color="info.main"
//                 icon={<ListAltIcon fontSize="large" />}
//                 vertical={true}
//               />
//             </Grid>
//           </Grid>
//         </TabPanel>
//         <TabPanel value={value} index={1} dir={theme.direction}>
//           <SellerTab />
//         </TabPanel>
//         <TabPanel value={value} index={2} dir={theme.direction}>
//           <Store />
//         </TabPanel>
//         <TabPanel value={value} index={3} dir={theme.direction}>
//           <StoreOrder />
//         </TabPanel>
//         <TabPanel value={value} index={4} dir={theme.direction}>
//           <Category />
//         </TabPanel>
// 				<TabPanel value={value} index={5} dir={theme.direction}>
//           <Tranding />
//         </TabPanel>
// 				<TabPanel value={value} index={6} dir={theme.direction}>
//           <AddProduct />
//         </TabPanel>
//         <TabPanel value={value} index={7} dir={theme.direction}>
//           <StoreProduct />
//         </TabPanel>
//         <TabPanel value={value} index={8} dir={theme.direction}>
//           <ReferralProduct />
//         </TabPanel>
//       </SwipeableViews>
//     </Box>
//   );
// }

// function TabPanel({ children, value, index, ...other }) {
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }





import * as React from "react";
import Box from "@mui/material/Box";
import { CircularProgress, Grid } from "@mui/material";
import { ObjectCountRevenue } from "app/shared/metrics/ObjectCountCards";
import { postRequest } from "backendServices/ApiCalls";
import { ShoppingCartOutlined } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

export default function MarketplaceDashboard() {
  const [dashboardData, setDashboardData] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  const dashboardApiFunction = () => {
    postRequest("/marketplaceDashboard","",async (response) => {
        setLoader(true);
        // console.log("response?.data", response?.data?.data);
        if (response?.data?.status === "success") {
          setDashboardData(response?.data?.data[0]);
          setLoader(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoader(false);
      }
    );
  };

  React.useEffect(() => {
    dashboardApiFunction();
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Box>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} lg={6}>
          <ObjectCountRevenue
            value={dashboardData?.total_seller || 0}
            title="Total Sellers"
            color="info.main"
            icon={<PeopleAltIcon fontSize="large" />}
            vertical={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <ObjectCountRevenue
            value={dashboardData?.total_shops || 0}
            title="Total Shops"
            color="error.main"
            icon={<ShoppingCartOutlined fontSize="large" />}
            vertical={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <ObjectCountRevenue
            value={dashboardData?.total_products || 0}
            title="Total Products"
            color="info.main"
            icon={<LocalShippingIcon fontSize="large" />}
            vertical={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <ObjectCountRevenue
            value={dashboardData?.total_orders || 0}
            title="Total Orders"
            color="info.main"
            icon={<ListAltIcon fontSize="large" />}
            vertical={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}