import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { ApprovekycApi, RejectkycApi, deletenews, getkycreport, getnews, postRequest } from 'backendServices/ApiCalls';
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ImageIcon from '@mui/icons-material/Image';
import { format } from 'date-fns';
import { GppBad, VerifiedUser } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';


const useStyles = makeStyles((theme) => ({
    backdropBlur: {
      backdropFilter: 'blur(8px)',
      '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zIndex.modal - 1,
    },
  }))

  const useSweetAlert = (setKYCPending,setOpen) => {
    const [alertData, setAlertData] = useState({
      show: false,
      message: '',
      variant: '',
      confirmFunction: null,
      action: '',
    });
  
    const handleApprove = (id, amount) => {
  
      return new Promise((resolve) => {
        let params = { id: id }
        ApprovekycApi(params, (response) => {

            Swal.fire({
                title: 'Kyc Approved',
                text: 'Kyc approved successfully',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',
              }).then(() => {
                setAlertData((prevAlertData) => ({
                  ...prevAlertData,
                  show: false,
                }));
              });
              setKYCPending((data) => data.filter((row) => row.id !== id));
              setOpen(false)
              resolve()

        }, (error) => {
            console.log(error?.response?.data);
        });

      })
    };
  
    const handleReject = (id, reason) => {

      return new Promise((resolve) => {
        let params = {
          id: id,
          reason:reason
        };
  
        RejectkycApi(params, (response) => {
            Swal.fire({
                title: 'Kyc Rejected',
                text: 'with reason "'+reason+'"',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK',
              }).then(() => {
                setAlertData((prevAlertData) => ({
                  ...prevAlertData,
                  show: false,
                }));
              });
              setKYCPending((data) => data.filter((row) => row.id !== id));
              setOpen(false)
              resolve()

        }, (error) => {
            resolve()
            console.log(error?.response?.data);
        });
      })
    };
  
    const showSweetAlert = (title, description, confirmFunction, variant, action) => {
      setAlertData({
        show: true,
        message: description,
        variant: variant,
        confirmFunction: confirmFunction,
        action: action,
      });
    };
  
    useEffect(() => {
      if (alertData.show) {
        const hasTextField = alertData.action === 'rejected';
        Swal.fire({
          title: alertData.title,
          html: `<div>${alertData.message}</div>${hasTextField ? '<input type="text" id="reason-input" class="swal2-input" placeholder="Reason for rejection" required="">' : ''}`,
          text: alertData.message,
          icon: alertData.variant,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return new Promise(async (resolve) => {
              if (hasTextField) {
                const reason = document.getElementById('reason-input').value;
                if (!reason) {
                  Swal.showValidationMessage('Please enter a reason for rejection');
                  resolve(false);
                } else {
                  await alertData.confirmFunction(reason);
                  resolve();
                }
              } else {
                await alertData.confirmFunction();
                resolve();
              }
            });
          },
          allowOutsideClick: () => !Swal.isLoading(),
          didOpen: () => {
            if (hasTextField) {
              const input = document.getElementById('reason-input');
              if (input) {
                input.focus();
              }
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle the case when "Yes" is clicked
          } else {
            // Handle the case when "No" is clicked
          }
        });
      }
    }, [alertData]);
  
    return {
      showSweetAlert,
      handleApprove,
      handleReject,
    };
  };

const KYCPending = () => {
    const [kycpending, setKYCPending] = useState([]);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [selectedData, setSelectedData] = useState(null); // New state variable
    const [loadingRows, setLoadingRows] = useState({}); // New state variable to track loading state of each row
    const { showSweetAlert, handleApprove, handleReject } = useSweetAlert(setKYCPending,setOpen);


    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    const Getkycpending = () => {
        postRequest('/kycreport', {status:"Pending"}, (response) => {
            setKYCPending(response?.data?.data);
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    useEffect(() => {
        Getkycpending();
    }, [])

    const handleImage = (image) => {
        if (image) {
            const imageUrl = `https://mptestbackend.m5networkhub.com/uploads/kyc/${image}`;
            window.open(imageUrl, '_blank');
        }
    };

    const handleCheckDocuments = (id) => {
        setLoadingRows(prevState => ({ ...prevState, [id]: true }));
        const selected = kycpending.find(data => data.id === id);
        setSelectedData(selected);
        setOpen(true);
    }

    const handleActionClick = (id, action) => {
        let title = '';
        let description = '';
        let confirmFunction = null;
        let variant = ''
    
        if (action === 'approved') {
          title = 'Approve payout';
          description = `Are you sure you want to Verify this user`;
          confirmFunction = ()=>handleApprove(id);
          variant = 'warning';
        } else if (action === 'rejected') {
            setOpen(false)

          title = 'Reject payout';
          description = `Are you sure you want to reject this kyc`;
          confirmFunction = (reason) => handleReject(id, reason);
          variant = 'error';
        }
    
        showSweetAlert(title, description, confirmFunction,variant,action);
      };

    const columns = [
        {
            field: "username",
            headerName: "User Name",
            width: 200,

            groupable: false,
        },
        {
            field: "fullname",
            headerName: "Name",
            width: 200,


        },

        {
            field: "type",
            headerName: "Type",
            width: 200,

            groupable: false,
        },
        {
            field: "date",
            headerName: "Date",
            width: 200,
            renderCell: (params) => {
                return format(new Date(params.row.date), 'MM/dd/yyyy');
            }

        },

        {
            field: 'CheckDocuments',
            headerName: 'Check Documents',
            width: 200,
            renderCell: (params) => (
                <Button 
                variant='contained' 
                size='small' 
                color='info' 
                onClick={() => handleCheckDocuments(params.row.id)} 
                endIcon={<VerifiedUser />}
                disabled={loadingRows[params.row.id]} 
                >
                   {loadingRows[params.row.id] ? <CircularProgress size={20} /> : 'Verify Documents'}
                </Button>
            ),
        },


    ]




    const gridDesign = {
        '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
                fontSize: '13px !important',
                color: '#8cda24',
            },
            '& .MuiBadge-badge': {
                backgroundColor: '#074682',
            },
            '& .MuiInput-root': {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: 'hidden',
            },

        }
    }

    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={12} xs={12}>
                <JumboDemoCard
                    title={'KYC Pending'}
                    wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
                >
                    {open && <div className={classes.backdropBlur}></div>}
                    <Dialog
                        open={open}
                        keepMounted
                        onClose={() => {
                            setOpen(false);
                        }}
                        aria-describedby="alert-dialog-slide-description"
                        maxWidth="xs"
                    >
                        <DialogContent sx={{ padding: 2, minWidth:'500px' }}>
                            {selectedData && (
                                    <TableContainer component={Paper}>
                                    <Table>
                                      <TableBody>

                                         <TableRow >
                                            <TableCell component="th" scope="row">Username</TableCell>
                                            <TableCell>{selectedData.username}</TableCell>
                                          </TableRow>


                                          <TableRow >
                                            <TableCell component="th" scope="row">Full Name</TableCell>
                                            <TableCell>{selectedData.fullname}</TableCell>
                                          </TableRow>


                                          <TableRow >
                                            <TableCell component="th" scope="row">Email</TableCell>
                                            <TableCell>{selectedData.email}</TableCell>
                                          </TableRow>

                                          <TableRow >
                                            <TableCell component="th" scope="row">Phone</TableCell>
                                            <TableCell>{selectedData.phone}</TableCell>
                                          </TableRow>

                                          <TableRow >
                                            <TableCell component="th" scope="row">Address</TableCell>
                                            <TableCell>{selectedData.address}</TableCell>
                                          </TableRow>


                                          <TableRow >
                                            <TableCell component="th" scope="row">Document Type</TableCell>
                                            <TableCell><Chip size='small'    label={selectedData.type} color='success' /></TableCell>
                                          </TableRow>


                                          <TableRow >
                                            <TableCell component="th" scope="row">Document 1</TableCell>
                                            <TableCell><Button color='warning' variant='contained' onClick={()=>handleImage(selectedData?.id_front)} size='small' sx={{borderRadius:'50px'}}>Check Document</Button></TableCell>
                                          </TableRow>

                                          {
                                           selectedData.type !== "Passport" ? (

                                            <TableRow >
                                                <TableCell component="th" scope="row">Document 2</TableCell>
                                                <TableCell><Button color='info' variant='contained' onClick={()=>handleImage(selectedData?.id_back)} size='small' sx={{borderRadius:'50px'}}>Check Document</Button></TableCell>
                                             </TableRow>
                                           )
                                           :
                                           null 
                                          }


                                            <TableRow >
                                                <TableCell component="th" scope="row"><Button color='error' variant='contained' size='small' onClick={()=>handleActionClick(selectedData?.id, 'rejected')} sx={{borderRadius:'50px'}} endIcon={<GppBad />}>Reject</Button></TableCell>
                                                <TableCell><Button color='success'  onClick={()=>handleActionClick(selectedData?.id, 'approved')} variant='contained' size='small' sx={{borderRadius:'50px'}} endIcon={<VerifiedUser />}>Approve</Button></TableCell>
                                             </TableRow>

                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="warning"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }


                    <Box sx={{ height: 500, width: 1 }}>
                        <DataGrid
                            initialState={{
                                pagination: { paginationModel: { pageSize: 6 } },
                            }}
                            rows={kycpending}

                            getRowId={(row) => row.id}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            sx={gridDesign}
                            pageSizeOptions={[6, 12, 18, 24, 30]}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Box>
                    {/* )} */}
                </JumboDemoCard>
            </Grid></Grid>
    )
}

export default KYCPending