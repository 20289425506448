import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from "@mui/material";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import { dashboardAPI } from 'backendServices/ApiCalls';
import { Group, VerifiedUser } from '@mui/icons-material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import StoreIcon from '@mui/icons-material/Store';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Div from '@jumbo/shared/Div';
import PortfolioBalance from 'app/shared/metrics/PortfolioBalance/PortfolioBalance';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const Crypto = () => {

    const [dashboardData, setDashboardData] = useState(null)
    const [loader, setLoader] = useState(true)

    const dashboardApiFunction = () => {
        dashboardAPI((response) => {
            setLoader(false)
            setDashboardData(response?.data?.data)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    useEffect(() => {
        dashboardApiFunction()
    }, [])

    if (loader) {
        return (
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              alignContent: "center",
              height: "100%",
              justifyContent: "center", // Align items horizontally
              textAlign: 'center'
            }}
          >
            <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

          </Div>
        );
    }


    return (
        <Grid container spacing={3.75}>

            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.active_users} title='Active Users' color="info.main" icon={<VerifiedUser fontSize='large' />} vertical={true} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.inactive_users || 0} title='Inactive Users' color="error.main" icon={<UnpublishedIcon fontSize='large' />} vertical={true} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashboardData?.total_users} title='Total Users' color="info.main" icon={<Group fontSize='large' />} vertical={true} />
            </Grid>

            <Grid item xs={12} sm={12} lg={6}>
                <PortfolioBalance dashboardApiData={dashboardData} />
            </Grid>

            <Grid item xs={12} sm={12} lg={6}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={6} lg={6}>
                        <ObjectCountRevenue value={dashboardData?.pending_payout} title='Pending Payout Requests' color="info.main" icon={<CurrencyExchangeIcon fontSize='medium' />} vertical={true} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <ObjectCountRevenue value={dashboardData?.currentMonth_payout} title='Current Month Payout' color="primary.main" icon={<CurrencyExchangeIcon fontSize='medium' />} vertical={true} />
                    </Grid>
                    <Grid sx={{ marginTop: -3 }} item xs={12} sm={6} lg={6}>
                        <ObjectCountRevenue value={dashboardData?.allTime_payout} title='All time Payout' color="info.main" icon={<CurrencyExchangeIcon fontSize='medium' />} vertical={true} />
                    </Grid>
                    <Grid sx={{ marginTop: -3 }} item xs={12} sm={6} lg={6}>
                        <ObjectCountRevenue value={dashboardData?.pending_pkgs} title='Pending Packages' color="primary.main" icon={<ProductionQuantityLimitsIcon fontSize='medium' />} vertical={true} />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default Crypto;
