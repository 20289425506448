import {alpha} from "@mui/material/styles";

export const sidebarTheme = {
    type: "light",
    palette: {
        primary: {
            main: '#8e4cd0',
            light: '#ffd08b',
            dark: '#1C6C5C',
            contrastText: '#000'
        },
        secondary: {
            main: '#E44A77',
            light: '#FF7EA6',
            dark: '#DF295E',
            contrastText: '#000'
        },
        error: {
            main: '#E73145',
            light: '#FF6A70',
            dark: '#AD001E',
            contrastText: '#000'
        },
        warning: {
            main: '#F39711',
            light: '#FFC84C',
            dark: '#BB6900',
            contrastText: '#000'
        },
        info: {
            main: '#2EB5C9',
            light: '#6FE7FC',
            dark: '#008598',
            contrastText: '#000'
        },
        success: {
            main: '#3BD2A2',
            light: '#78FFD3',
            dark: '#00A073',
            contrastText: '#000'
        },
        text: {
            primary: '#02555b',
            secondary: '#D3DCE6',
            disabled: '#A2B2C3',
        },
        nav: {
            action: {
                active: '#8cda24',
                hover: '#fff',
            },
            background: {
                active: '#02555b',
                hover: "#02555b"
            },
            tick: {
                active: '#44aa16',
                hover: "#ADB5BD"
            }
        },
        divider : '#DEE2E6',
        background: {
            paper: '#fff',
            default: '#F5F7FA',
        },
        action: {
            active: '#dbcbcb',
            hover: '#F5F7FA',
        },
    }
};