import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Box, CircularProgress, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function NewSeller() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });

    const SellerData = () => {
        setLoading(true);
        postRequest("/getsellerforapprove", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data?.map((row, index) => ({
                    id: index + 1,
                    approvedId: row?.id || "",
                    rejectId: row?.id || "",
                    username: row?.username || "",
                    email: row?.email || "",
                    firstname: row?.firstname || "",
                    lastname: row?.lastname || "",
                    address: row?.shopaddress + "," + row?.shopcity + "," + row?.shopstate + "," + row?.shopzipcode + "," + row?.shopcountry + ", Mobile: " + row?.shopmobile || "",
                    birthdate: row?.birth_date,
                }));

                setData(formattedData);
                setLoading(false);
            }
            if (response?.data?.status === "not found") {
                // setalertData({
                //     show: true,
                //     message: response?.data?.message,
                //     variant: "success",
                // });
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                // setalertData({
                //     show: true,
                //     message: response?.data?.message,
                //     variant: "success",
                // });
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        SellerData()
    }, [])


    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80 },
        { field: "firstname", headerName: "First Name", width: 150 },
        { field: "lastname", headerName: "Last Name", width: 150 },
        { field: "username", headerName: "Username", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "birthdate", headerName: "Date of Birth", width: 200 },
        {
            field: "address", headerName: "Shop Address", width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{params.value}</div>
                </Tooltip>
            ),
        },
        { field: "approvedId", headerName: "Approve Seller", width: 150, renderCell: (params) => (<HowToRegIcon style={{ cursor: 'pointer' }} onClick={() => handleApprove(params.value, "approved")} />) },
        { field: "rejectId", headerName: "Reject Seller", width: 150, renderCell: (params) => (<PersonOffIcon style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleApprove(params.value, "rejected")} />) },
    ];

    const handleApprove = (id, status) => {
        let param = {
            id, status
        }
        // console.log('param', param);
        // return
        postRequest("/approveseller", param, async (response) => {
            // console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success",
                });
                SellerData()
                if (data.length == 1) setData([])
            }
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error",
                });
            }
        },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    };

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    username: false,
                    store_name: false,
                    total_products: false,
                    email: false,
                    firstname: false,
                    lastname: false,
                },
            },
        },
    };


    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    return (
        <JumboDemoCard title={"New Seller"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>
        </JumboDemoCard>
    )
}
