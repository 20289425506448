import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { IconButton, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon, ArrowRightAlt } from '@mui/icons-material';
import { format } from 'date-fns';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const VISIBLE_FIELDS = ['sr','receiverusername', 'amount', 'payoutmethod', 'payoutaccount2','rejectreason', 'createdat'];

const RejectedPayout = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [copiedRows, setCopiedRows] = useState([]);
  const [usersData, setUsersData] = useState([]);

  let params = {
    status: 'rejected',
    type:'payout',
    usertype:'receiver'
  };


  const UsersData = () => {
    postRequest(
      '/selecttransactions',
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          setUsersData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };



  const rows = usersData;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#8cda24',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Rejected payouts'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 24 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => row.id}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'receiverusername') {
                return {
                  field,
                  headerName: 'Username',
                  width: 150,
                };
              }  else if (field === 'amount') {
                return {
                  field,
                  headerName: `Amount`,
                  width: 150,
                  renderCell: (params) => {
                    return params.row.payoutmethod === "Matic" ? params.value + ' Matic' : '$' + params.value;
                  }
                };
              }else if (field === 'createdat') {
                return {
                  field,
                  headerName: 'Date',
                  width: 150,
                  renderCell:(params) => {
                    return  format(new Date(params.row.createdat), 'MM/dd/yyyy');
       
                   }
                };
              }else if (field === 'rejectreason') {
                return {
                  field,
                  headerName: 'Reason',
                  width: 150,
                };
              }
              else if (field === 'payoutmethod') {
                return {
                  field,
                  headerName: `Payout Method`,
                  width: 150,
                  renderCell: (params) => {
                    
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      
                        {
                          params.value === "Matic" ? 
                          (
                            <img src={`${ASSET_IMAGES}/maticicon.png`} style={{ width: '28px' }}  />
      
                          )
                          :
                          (
                            <img src={`${ASSET_IMAGES}/dollaricon.png`} style={{ width: '25px' }}  />
      
                          )
                        }
                        <ArrowRightAlt  />
                        <img src={`${ASSET_IMAGES}/maticicon.png`} style={{ width: '28px' }}  />
                        
                      </div>
                    )
                  },
                };
              } 
              else if (field === 'payoutaccount2') {
                return {
                  field,
                  headerName: 'Payout Address',
                  width: 150,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);
                    
                    const handleCopyClick = () => {
                      navigator.clipboard.writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                        })
                        .catch((error) => {
                          console.error('Copy failed:', error);
                        });
                    };
          
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isCopied ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : (
                          <Tooltip title="Copy Hash" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              }
              return {
                field,
                headerName: field,
                width: 150,
              };;
            }),
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[24, 48, 96]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default RejectedPayout;
