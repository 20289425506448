import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Box, Button, CircularProgress, Modal, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import SweetAlertConfirm from './SweetAlertConfirm';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import CreateIcon from '@mui/icons-material/Create';
import StoreUpdate from './StoreUpdate';
import { useNavigate } from 'react-router-dom';

export default function Store() {
    const nav = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [bannerLink, setBannerLink] = useState(false)
    const [logoLink, setLogoLink] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateProductData, setUpdateProductData] = useState({});
    const [alertData, setalertData] = useState({ show: false, message: "", variant: "", });
    const [confirmDelete, setConfirmDelete] = useState({ show: false, id: null, processing: false });

    const StoreData = () => {
        setLoading(true);
        postRequest("/storeData", "", async (response) => {
            // console.log("response?.data", response?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index + 1,
                    username: row?.username || "",
                    store_name: row?.store_name || "",
                    store_desc: row?.store_desc || "",
                    total_products: row?.total_products || 0,
                    store_banner_image: row?.store_banner_image || "",
                    store_logo: row?.store_logo || "",
                    tags: row?.tags || "",
                    shopId: row?.id || 0,
                    updateshop: row?.id || 0,
                }));
                setData(formattedData);
                setBannerLink(response?.data?.banner_link)
                setLogoLink(response?.data?.logo_link)
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        StoreData()
    }, [])


    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80, },
        {
            field: "store_banner_image", headerName: "Banner", width: 150,
            renderCell: (params) => (
                <div>
                    <img src={bannerLink + params.value} style={{ cursor: 'pointer', width: '30%' }} alt="" />
                </div>
            )
        },
        {
            field: "store_logo", headerName: "Logo", width: 150,
            renderCell: (params) => (
                <div>
                    <img src={logoLink + params.value} style={{ cursor: 'pointer', width: '30%' }} alt="" />
                </div>
            )
        },
        {
            field: "store_name", headerName: "Store Name", width: 150,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{params.value}</div>
                </Tooltip>
            ),
        },
        { field: "total_products", headerName: "Total Products", width: 150, },
        // {
        //     field: "store_desc", headerName: "Description", width: 150,
        //     renderCell: (params) => (<div dangerouslySetInnerHTML={{ __html: params.value }} />)
        // },
        {
            field: "store_desc",
            headerName: "Description",
            width: 150,
            renderCell: (params) => (
                <Tooltip title={<div dangerouslySetInnerHTML={{ __html: params.value }} />} arrow>
                    <div dangerouslySetInnerHTML={{ __html: params.value }} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} />
                </Tooltip>
            ),
        },
        { field: "username", headerName: "Username", width: 150, align: "center" },
        { field: "updateshop", headerName: "Update", width: 150, renderCell: (params) => (<CreateIcon style={{ cursor: 'pointer' }} onClick={() => handleProductUpdate(params.row)} />) },
        {
            field: "shopId", headerName: "Delete", width: 150,
            renderCell: (params) => (
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(params.value)} />
            )
        },
    ];
    const handleProductUpdate = (productData) => {
        setUpdateProductData(productData);
        setUpdateModalOpen(true);
    };

    const handleDelete = (id) => {
        setConfirmDelete({ show: true, id, processing: true });
        postRequest("/deleteshop", { id }, async (response) => {
            if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: 'Shop deleted successfully',
                    variant: 'success'
                });
                // CategoryData();  // Refresh data
                setData((prevData) => prevData.filter(shop => shop.shopId !== id));
            } else {
                setalertData({
                    show: true,
                    message: 'Shop not deleted',
                    variant: 'error'
                });
            }
            setConfirmDelete({ show: false, id: null, processing: false });
        }, (error) => {
            console.log(error?.response?.data);
            setConfirmDelete({ show: false, id: null, processing: false });
        });
    };


    const handleDeleteClick = (id) => {
        setConfirmDelete({ show: true, id, processing: false });
    };

    const confirmDeleteAction = (confirm) => {
        if (confirm) {
            handleDelete(confirmDelete.id);
        } else {
            setConfirmDelete({ show: false, id: null, processing: false });
        }
    };

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    store_name: false,
                    store_desc: false,
                    total_products: false,
                    username: false,
                },
            },
        },
    };

    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button variant="contained" color="primary" onClick={()=>{nav("/marketPlaceStoreNew")}}>
                    Add Store
                </Button>
            </Box>
            <JumboDemoCard title={"Stores"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
                {alertData.show && (
                    <SweetAlert alertData={alertData} setalertData={setalertData} />
                )}
                {confirmDelete.show && (
                    <SweetAlertConfirm
                        alertData={{
                            show: true,
                            title: "Confirm Delete",
                            message: confirmDelete.processing ? "Deleting..." : "Are you sure you want to delete this category?",
                            variant: "warning",
                            processing: confirmDelete.processing,
                            actions: confirmDelete.processing ? [] : [
                                // { label: "Cancel", action: () => confirmDeleteAction(false) },
                                { label: "Delete", action: () => confirmDeleteAction(true), color: "error" }
                            ]
                        }}
                        setAlertData={setConfirmDelete}
                    />
                )}
                <Box sx={{ height: 500, width: 1 }}>
                    <DataGrid
                        initialState={{
                            initialState,
                            pagination: { paginationModel: { pageSize: 15 } },
                        }}
                        rows={data}
                        // getRowId={(row) => row.id}
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        sx={gridDesign}
                        pageSizeOptions={[15, 30, 75, 100]}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Box>
                <Modal
                    open={updateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    aria-labelledby="update-modal-title"
                    aria-describedby="update-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '60%',
                            height: '70%',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <StoreUpdate logoLink={logoLink} bannerLink={bannerLink} updateProductData={updateProductData} StoreData={StoreData} setUpdateModalOpen={setUpdateModalOpen} />
                        {/* updateProductData actual data to pass for ini */}
                        {/* SellerData call to get data again */}
                        {/* setUpdateModalOpen to close it again */}
                        {/* <UpdateProduct updateProductData={updateProductData} SellerData={SellerData} link={link} setUpdateModalOpen={setUpdateModalOpen} /> */}
                    </Box>
                </Modal>
            </JumboDemoCard>
        </>
    )
}
