import React, { useCallback, useContext, useState } from "react";
import MessagesTriggerButton from "./MessagesTriggerButton";
import MessagesHeader from "./MessagesList/MessagesHeader";
import SettingHeader from "./MessagesSetting/SettingHeader";
import SettingsList from "./MessagesSetting/SettingsList";
import MessagesList from "./MessagesList";
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import { CardActions, ThemeProvider, Typography } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { postRequest } from "backendServices/ApiCalls";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const MessagesDropdown = () => {
  const {t} = useTranslation();
  const [showSettings, setShowSettings] = useState(false);
  const { theme } = useJumboTheme();
  const nav = useNavigate();
  const [messagesData, setMessagesData] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const { loginUserData } = useContext(CustomProvider);
  const [newmessages, setNewMessages] = React.useState(1);

  const toggleSettingWindow = useCallback(() => {
    setShowSettings((showSettings) => !showSettings);
  }, [setShowSettings]);

  function getMessagesData() {
    postRequest(
      "/getmessageslistDropDown",
      "",
      async (response) => {
        if (response?.data?.status === "success") {
          await setMessagesData(response?.data?.data);
          await setNewMessages(loginUserData?.newMessage === 1 ? response?.data?.data?.enteries?.length : '0')
          await setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }

  React.useEffect(async () => {
    await getMessagesData();
  
  }, []);

  // const clearmessages = () =>{
  //     updatemessagestatus((response) => {

  //     }, (error) => {
  //         console.log(error?.response?.data);
  //     })

  // }
  const messages = messagesData?.enteries?.filter(message => message.mstatus === 'unseen');



  if (isLoading) {
    return <div></div>;
  }
  console.log('newmessages',messages.length)

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={<MessagesTriggerButton setNewMessages={setNewMessages} newmessages={messages.length} />}
        disableInsideClick
      >
        {showSettings ? (
          <Div sx={{ width: 360, maxWidth: "100%" }}>
            <SettingHeader backClickCallback={toggleSettingWindow} />
            <SettingsList />
          </Div>
        ) : (
          <Div sx={{ width: 360, maxWidth: "100%"}}>
            <MessagesHeader settingMenuCallback={toggleSettingWindow} />
            {
              messages.length > 0 ? (
                <MessagesList messagesData={messages} />
              )
              :
              (
                <Typography variant="body2" textAlign='center' color='primary'>No new message</Typography>
              )
            }
            <Divider />
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={() => nav("/inbox")}
                sx={{
                  textTransform: "none",
                  fontWeight: "normal",
                  "&:hover": { bgcolor: "transparent" },
                }}
                size={"small"}
                variant="text"
                endIcon={<ArrowForwardIcon />}
                disableRipple
              >
                View All
              </Button>
            </CardActions>
          </Div>
        )}
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default MessagesDropdown;
