import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Clock } from '@mui/x-date-pickers/ClockPicker/Clock';
import { makeStyles } from '@mui/styles';
import { Approval, Close, Message } from '@mui/icons-material';
import SendRankMessage from '../messages/SendRankMessage';


const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}));


const initialState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
      }
    }
  }
}


const PendingAchievers = () => {

  const [payoutdata, setPayoutData] = useState([])
  const [alertopen, setAlertOpen] = useState(false);
  const [rowData, setRowData] = useState('');
  const [username, setUsername] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const classes = useStyles();


  const Swal = useSwalWrapper();

  const approveOrder = (orderId) => {
    Swal.fire({
      title: 'Are you sure to approve this?',
      // text: "You are going apporve status ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Approve!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        handleOrderStatus(orderId)
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire('Cancelled', 'Rank achiever is not approved', 'error');
      }
    });
  };



  const handleOrderStatus = (id) => {
    let params = {
      id: id,
    }
    postRequest(
      '/approverankachievers',
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          setAlertOpen(true)
          const nPayoutData = payoutdata.filter((pdata) => pdata.id !== id)
          setPayoutData(nPayoutData);
          Swal.fire('Approved!', 'Rank achiever has been approved successfully.', 'success');

        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }


  const PayoutData = () => {
    postRequest('/rankachievers', { type: 'pending' }, (response) => {
      if (response?.data?.status === "success") {
        setPayoutData(response?.data?.data)
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    PayoutData();
  }, [])


  const messageDialog = (rowData) =>{
    setRowData(rowData)
    setOpenMessageDialog(true)

  }
  
  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false)
  }

  const columns = [
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "ranks_name",
      headerName: "Rank",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "reward",
      headerName: "Rank Reward",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },

    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === 'pending' ? (<Chip color='warning' size='small' label='Pending' />)
          :
          params.value === 'rejected' ? (<Chip color='error' size='small' label='Rejected' />)
            :
            (<Chip color='success' size='small' label='Approved' />)
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 350,
      renderCell: (params) => (
        <div>
          <Button sx={{ marginLeft: 2, borderRadius:'50px' }} open={alertopen} size='small' variant='contained' onClick={() => approveOrder(params.row.id)} endIcon={<Approval />}>Approve</Button>

          <Button sx={{ marginLeft: 2, borderRadius:'50px' }} color='info' size='small' variant='contained' onClick={() => messageDialog(params.row)} endIcon={<Message />}>Send Message</Button>
        </div>
      ),
    },
  ]

  const rows = payoutdata
  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#8cda24',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }

  return (
    <JumboDemoCard
      title={"Pending Rank Achievers"}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >

{openMessageDialog && <div className={classes.backdropBlur}></div>}
        <Dialog open={openMessageDialog}  onClose={handleCloseMessageDialog}>
          <DialogTitle>Send Message to {rowData?.username}</DialogTitle>
          <IconButton
          aria-label="close"
          onClick={handleCloseMessageDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
          <DialogContent>
              <SendRankMessage rowData={rowData} handleCloseMessageDialog={handleCloseMessageDialog}  />
          </DialogContent>


        </Dialog>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default PendingAchievers