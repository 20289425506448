import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import { Box, Button, CircularProgress, Modal, Slide } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import ImageIcon from '@mui/icons-material/Image';
import Div from '@jumbo/shared/Div';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import SweetAlertConfirm from "./SweetAlertConfirm";
import { useNavigate } from 'react-router-dom';

export default function ManageCategory() {
    const nav = useNavigate()
    const [data, setData] = useState([])
    const [link, SetLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [imageLoad, setImageLoad] = useState(true);
    const [imageSrc, setImageSrc] = useState('');
    const [alertData, setalertData] = useState({ show: false, message: "", variant: "", });
    const [confirmDelete, setConfirmDelete] = useState({ show: false, id: null, processing: false });

    const CategoryData = () => {
        setLoading(true);
        postRequest("/getCategoryInfo", "", async (response) => {
            // console.log("response?.data", response?.data?.data);
            if (response?.data?.status === "success") {
                const formattedData = response?.data?.data.map((row, index) => ({
                    id: index + 1,
                    CategoryId: row?.id || 0,
                    name: row?.name || "",
                    icon: row?.icon || "",
                    banner: row?.banner || "",
                    status: row?.status || "",
                }));
                setData(formattedData);
                SetLink(response?.data?.link)
                setLoading(false);
            }
            if (response?.data?.status === "error") {
                setLoading(false);
            }
        },
            (error) => {
                console.log(error?.response?.data);
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        CategoryData()
    }, [])


    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
                color: "#8cda24",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    const handleImageClick = (imageUrlArray) => {
        setImageSrc(imageUrlArray);
        setModalOpen(true);
    };

    const handleDelete = (id) => {
        // setConfirmDelete(prev => ({ ...prev, processing: true }));
        setConfirmDelete({ show: true, id, processing: true });
        postRequest("/deleteCategory", { id }, async (response) => {
            if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: 'Category deleted successfully',
                    variant: 'success'
                });
                // CategoryData();  // Refresh data
                setData((prevData) => prevData.filter(category => category.CategoryId !== id));
            } else {
                setalertData({
                    show: true,
                    message: 'Category not deleted',
                    variant: 'error'
                });
            }
            setConfirmDelete({ show: false, id: null, processing: false });
        }, (error) => {
            console.log(error?.response?.data);
            setConfirmDelete({ show: false, id: null, processing: false });
        });
    };
    

    const handleDeleteClick = (id) => {
        setConfirmDelete({ show: true, id, processing: false });
    };

    const confirmDeleteAction = (confirm) => {
        if (confirm) {
            handleDelete(confirmDelete.id);
        }else{
            setConfirmDelete({ show: false, id: null, processing: false });
        }
    };

    const columns = [
        { field: "id", headerName: "Sr#", width: 80 },
        { field: "name", headerName: "name", width: 150 },
        { field: "status", headerName: "Status", width: 200 },
        // {
        //     field: "icon", headerName: "icon", width: 150,
        //     renderCell: (params) => (
        //         <img src={`/icon/${params.value}.svg`} alt='Icon' style={{ width: 24, marginRight: 8 }} />
        //     )
        // },
        {
            field: "banner", headerName: "Banner", width: 150,
            renderCell: (params) => (
                <ImageIcon style={{ cursor: 'pointer' }} onClick={() => handleImageClick(link + params.value)} />
            )
        },
        {
            field: "CategoryId", headerName: "Delete", width: 150,
            renderCell: (params) => (
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(params.value)} />
            )
        },
    ];

    const initialState = {
        initialState: {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    name: false,
                    icon: false,
                    banner: false,
                    status: false,
                },
            },
        },
    };


    if (loading) {
        return (
            <Div
                sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 500,
                }}
            >
                <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
        );
    }

    return (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button variant="contained" color="primary" onClick={()=>{nav("/marketPlaceCategory")}}>
                    Add Category
                </Button>
            </Box>
        <JumboDemoCard title={"Manage Category"} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            {confirmDelete.show && (
                <SweetAlertConfirm
                    alertData={{
                        show: true,
                        title: "Confirm Delete",
                        message: confirmDelete.processing ? "Deleting..." : "Are you sure you want to delete this category?",
                        variant: "warning",
                        processing: confirmDelete.processing,
                        actions: confirmDelete.processing ? [] : [
                            // { label: "Cancel", action: () => confirmDeleteAction(false) },
                            { label: "Delete", action: () => confirmDeleteAction(true), color: "error" }
                        ]
                    }}
                    setAlertData={setConfirmDelete}
                />
            )}
            <Box sx={{ height: 500, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                    }}
                    rows={data}
                    // getRowId={(row) => row.id}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[15, 30, 75, 100]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>


            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        // height:'50%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    { imageLoad &&
                        <Div
                            sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                alignContent: "center",
                                height: 100,
                            }}>
                            <CircularProgress sx={{ m: "-40px auto 0" }} />
                        </Div>
                    }
                    <div className="slide-container">
                        {/* <Slide> */}
                        <img onLoad={()=> setImageLoad(false)} src={imageSrc} alt='Banner Image' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                        {/* </Slide> */}
                    </div>
                </Box>
            </Modal >
        </JumboDemoCard >
        </>
    )
}
