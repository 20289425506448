import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  TextField,
  CircularProgress,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { postRequest } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { PhotoCamera } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  title: yup.string("Enter trending title").required("Title is required"),
});

function AddTranding() {
  const nav = useNavigate()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [link, setLink] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [thumbnailName, setThumbnailName] = useState([]);
  const [uploading, setUploading] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetProducts = () => {
    setLoading(true);
    postRequest(
      "/productcategory",
      "",
      async (response) => {
        if (response?.data?.status === "success") {
          const formattedData = response?.data?.data.map((row, index) => ({
            id: index + 1,
            name: row?.name,
            categoryId: row?.id,
          }));
          setData(formattedData);
          setLink(response?.data?.link);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetProducts();
  }, []);

  const handleSubmit = (formData, setSubmitting, resetForm) => {
    const selectedCount = selectedOptions.length;
  
    if (selectedCount === 0 || thumbnailName.length !== selectedCount || thumbnailName.includes('')) {
      setalertData({
        show: true,
        message: "Please select trending products and image before submitting the form.",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }
  
    // Check if the number of selected options is either 1 or 4
    if (selectedCount !== 1 && selectedCount !== 4) {
      setalertData({
        show: true,
        message: "You must select either 1 or 4 options.",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }
  
    const selectedIds = selectedOptions.map((option) => option.categoryId);
    const requestData = {
      ...formData,
      categoryIds: selectedIds,
      thumbnails: JSON.stringify(thumbnailName),
    };
  
    postRequest(
      "/addtranding",
      requestData,
      async (response) => {
        if (response?.data?.status === "success") {
          setSubmitting(false);
          resetForm();
          setThumbnailName([]);
          setImages([]);
          setSelectedOptions([]);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          nav('/marketPlaceManageTrending')
        } else {
          setSubmitting(false);
          resetForm();
          setThumbnailName([]);
          setImages([]);
          setSelectedOptions([]);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setSubmitting(false);
        setSelectedOptions([]);
        setalertData({
          show: true,
          message: error?.response?.data?.message || "An error occurred.",
          variant: "error",
        });
      }
    );
  };
    
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: 500,
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue.length <= 4) {
      setSelectedOptions(newValue);
    } else {
      // Optionally notify user
      setalertData({
        show: true,
        message: "You can only select up to 4 options.",
        variant: "error",
      });
    }
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => URL.revokeObjectURL(img.src); // Clean up the URL after image is loaded
      const newImages = [...images];
      newImages[index] = img.src;
      setImages(newImages);
    }
    // Show CircularProgress for this image upload
    const newUploading = [...uploading];
    newUploading[index] = true;
    setUploading(newUploading);

    let formData = new FormData();
    formData.append("image", file);
    postRequest("/uploadCategoryThumbnail", formData, (response) => {
      // console.log('response?.data?.data', response?.data?.data)
      if (response?.data?.status === "success") {
        setThumbnailName(prev => {
          const updated = [...prev];
          updated[index] = response?.data?.data;
          return updated;
        });
        // Hide CircularProgress after successful upload
        const newUploading = [...uploading];
        newUploading[index] = false;
        setUploading(newUploading);

        setalertData({
          show: true,
          message: 'Image uploaded successfully',
          variant: 'success'
        });
      } else {
        setalertData({
          show: true,
          message: 'Image not uploaded successfully',
          variant: 'error'
        });
        // Hide CircularProgress if upload failed
        const newUploading = [...uploading];
        newUploading[index] = false;
        setUploading(newUploading);
      }
    },
      (error) => {
        setalertData({
          show: true,
          message: 'Image not uploaded successfully',
          variant: 'error'
        });
        console.log(error?.response?.data);
        // Hide CircularProgress if upload failed
        const newUploading = [...uploading];
        newUploading[index] = false;
        setUploading(newUploading);
      });
  };

  return (
    <>
      <JumboDemoCard
        title={"Add Trending"}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
        <Box display="flex" width={"100%"} flexDirection="column" gap={2}>
          <Formik
            validateOnChange={true}
            initialValues={{
              title: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(formData, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSubmit(formData, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting }) => (
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
              >
                <Div sx={{ width: "100%" }}>
                  <JumboTextField
                    fullWidth
                    name="title"
                    label="Trending Title"
                    type="text"
                  />
                </Div>
                <Div sx={{ width: "100%", mt: 5 }}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={data}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Category"
                        placeholder="Favorites"
                      />
                    )}
                    onChange={handleAutocompleteChange}
                    value={selectedOptions}
                  // onChange={(event, value) => setSelectedOptions(value)}
                  />
                </Div>
                <Div sx={{ width: "100%", mt: 2 }}>
                  {selectedOptions.map((option, index) => (
                    <Box key={option.categoryId} sx={{ mb: 2 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <label>Upload Image for {option.name}:</label>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<PhotoCamera />}
                          disabled={!!images[index]} // Disable if an image has been uploaded
                        >
                          Upload Image
                          <input
                            type="file"
                            hidden
                            onChange={(event) => handleImageUpload(event, index)}
                          />
                        </Button>
                      </Box>
                      {/* {images[index] && (
                        <Box mt={2} position="relative" display="inline-block">
                          <Typography>Image Preview:</Typography>
                          <img
                            src={images[index]}
                            alt={`Image Preview for ${option.name}`}
                            style={{ maxWidth: '100%', height: 'auto' }}
                          />
                        </Box>
                      )} */}
                      {uploading[index] && (
                        <Box mt={2} position="relative" display="inline-block">
                          <Typography>Uploading...</Typography>
                          <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                        </Box>
                      )}
                      {images[index] && !uploading[index] && (
                        <Box mt={2} position="relative" display="inline-block">
                          <Typography>Image uploaded for {option.name}</Typography>
                          {/* <img
                            src={images[index]}
                            alt={`Image Preview for ${option.name}`}
                            style={{ maxWidth: '100%', height: 'auto' }}
                          /> */}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Div>
                <Div sx={{ width: "100%", mt: 2 }}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Div>
              </Form>
            )}
          </Formik>
        </Box>
      </JumboDemoCard>
      {/* <Grid container spacing={2}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid> */}
    </>
  );
}

export default AddTranding;
