import React from 'react'
import BinaryPoints from './BinaryPoints'
import MatchingBonus from './MatchingBonus'

const WeeklyReport = ({userData}) => {
  return (
    <>
        <h3>Weekly Report Of {userData?.username}</h3>

        <BinaryPoints userid={userData?.userid} />
        <br/>
        <br/>
        <MatchingBonus userid={userData?.userid} />
    </>

  )
}

export default WeeklyReport