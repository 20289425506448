import React from 'react';
import { Alert, Box, Button, CircularProgress, Divider, Modal, Typography } from '@mui/material';

const SweetAlertConfirm = ({ alertData, setAlertData }) => {
    const handleClose = () => {
        setAlertData({ ...alertData, show: false });
    };

    return (
        <Modal
            open={alertData.show}
            onClose={handleClose}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '400px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                }}
            >
                <Typography id="alert-modal-title" variant="h6" component="h2" gutterBottom>
                    {alertData.title || 'Alert'}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {alertData.processing ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>Processing...</Typography>
                    </Box>
                ) : (
                    <>
                        <Alert
                            variant="filled"
                            severity={alertData.variant}
                            sx={{ mb: 2 }}
                        >
                            {alertData.message}
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                            {alertData.actions?.map((action, index) => (
                                <Button
                                    key={index}
                                    variant="contained"
                                    color={action.color || "primary"}
                                    onClick={action.action}
                                    sx={{ minWidth: 100 }}
                                >
                                    {action.label}
                                </Button>
                            ))}
                            <Button variant="outlined" onClick={handleClose} sx={{ minWidth: 100 }}>
                                Close
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default SweetAlertConfirm;
