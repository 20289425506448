import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
	Autocomplete,
  Grid,
  List,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { postRequest } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";

const validationSchema = yup.object({
  city: yup.string().required("City is required"),
});

function AddCities() {
  const [image, setImage] = useState(null);
  const [countrieslList, setCountriesList] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSubmit = (data, setSubmitting, resetForm) => {
    console.log('form data', data)
    const params = {
      cityname: data.city,
      countryid: data.country.id,
      countrycode: data.country.iso2,
    }
    postRequest(
      "/addnewcity",
      params,
      (response) => {
        console.log(response);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          resetForm(); // Reset the entire form
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      }, 
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const fetchCountriesList = () => {
    postRequest("/getcountries", "", (response) => {
      setCountriesList(response?.data?.data);
    });
  };

  useEffect(() => {
    fetchCountriesList();
  }, []);

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Add New City"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <List disablePadding sx={{ mb: 2 }}>
            <Formik
              validateOnChange={true}
              initialValues={{
                country: "",
                city: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
									<Autocomplete
                      sx={{ width: "100%" }}
                      value={values.country}
                      onChange={(event, newValue) => {
                        setFieldValue("country", newValue || null);
                      }}
                      id="vehmake-select"
                      options={countrieslList}
                      autoHighlight
                      getOptionLabel={(option) => option?.name ?? ""}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          label="Select Country"
                          variant="outlined"
                          error={touched.country && errors.country}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="city"
                      label="City"
                      type="text"
                    />
                  </Div>
                      
                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      //loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
          </List>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
}

export default AddCities;
