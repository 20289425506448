import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FileCopy as FileCopyIcon,
  CheckCircle as CheckCircleIcon,
  Assessment,
  ArrowRightAlt,
} from "@mui/icons-material";
import WeeklyReport from "../weeklyreport/WeeklyReport";
import { format } from "date-fns";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Div from "@jumbo/shared/Div";

const VISIBLE_FIELDS = [
  "sr",
  "receiverusername",
  "amount",
  "payoutmethod",
  "payoutaccount2",
  "createdat",
  "social",
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useSweetAlert = (UsersData) => {
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
    confirmFunction: null,
    action: "",
  });

  const handleApprove = (id, amount, reason) => {
    return new Promise((resolve) => {
      let params = {
        action: "approved",
        tid: id,
        investmentamount: amount,
        trxhash: reason,
      };

      postRequest(
        "/payoutaction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            Swal.fire({
              title: "payout Approved",
              text: "payout approved successfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "OK",
            }).then(() => {
              setAlertData((prevAlertData) => ({
                ...prevAlertData,
                show: false,
              }));
            });
            UsersData();
            resolve();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const handleReject = (id, amount, reason) => {
    return new Promise((resolve) => {
      let params = {
        action: "rejected",
        tid: id,
        reason: reason,
      };

      postRequest(
        "/payoutaction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            Swal.fire({
              title: "payout Rejected",
              text: 'with reason "' + reason + '"',
              icon: "warning",
              showCancelButton: false,
              confirmButtonText: "OK",
            }).then(() => {
              setAlertData((prevAlertData) => ({
                ...prevAlertData,
                show: false,
              }));
            });
            UsersData();
            resolve();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const showSweetAlert = (
    title,
    description,
    confirmFunction,
    variant,
    action
  ) => {
    setAlertData({
      show: true,
      message: description,
      variant: variant,
      confirmFunction: confirmFunction,
      action: action,
    });
  };

  useEffect(() => {
    if (alertData.show) {
      const hasTextField =
        alertData.action === "rejected" || alertData.action === "approved";
      Swal.fire({
        title: alertData.title,
        html: `<div>${alertData.message}</div>${
          hasTextField
            ? `<input type="text" id="reason-input" class="swal2-input" placeholder="${
                alertData.action === "rejected"
                  ? "Reason for rejection"
                  : "Please enter trx hash"
              }" required="">`
            : ""
        }`,
        text: alertData.message,
        icon: alertData.variant,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(async (resolve) => {
            if (hasTextField) {
              const reason = document.getElementById("reason-input").value;
              if (!reason) {
                Swal.showValidationMessage(
                  `${
                    alertData.action === "rejected"
                      ? "Please enter a reason for rejection"
                      : "Please enter the hash for this transaction"
                  }`
                );
                resolve(false);
              } else {
                await alertData.confirmFunction(reason);
                resolve();
              }
            } else {
              await alertData.confirmFunction();
              resolve();
            }
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          if (hasTextField) {
            const input = document.getElementById("reason-input");
            if (input) {
              input.focus();
            }
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the case when "Yes" is clicked
        } else {
          // Handle the case when "No" is clicked
        }
      });
    }
  }, [alertData]);

  return {
    showSweetAlert,
    handleApprove,
    handleReject,
  };
};

const PendingPayout = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [copiedRows, setCopiedRows] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [socialProofUrl, setSocialProofUrl] = useState([]);
  const [socialProof, setSocialProof] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const { loginUserData } = useContext(CustomProvider);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState([])
  const [socialStatus, setSocialStatus] = useState([])

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  let params = {
    status: "pending",
    type: "payout",
    usertype: "receiver",
  };

  const UsersData = () => {
    postRequest(
      "/selecttransactions",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setUsersData(response?.data?.data);
          setSocialProofUrl(response?.data?.socialProofUrl);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const { showSweetAlert, handleApprove, handleReject } =
    useSweetAlert(UsersData);

  const handleActionClick = (id, amount, action) => {
    setSelectedUserId(id);
    let title = "";
    let description = "";
    let confirmFunction = null;
    let variant = "";

    if (action === "approved") {
      title = "Approve payout";
      description = `Are you sure you want to approve this payout of Amount $${amount} ?`;
      confirmFunction = (reason) => handleApprove(id, amount, reason);
      variant = "warning";
    } else if (action === "rejected") {
      title = "Reject payout";
      description = `Are you sure you want to reject this payout mount $${amount} ?`;
      confirmFunction = (reason) => handleReject(id, amount, reason);
      variant = "error";
    }

    showSweetAlert(title, description, confirmFunction, variant, action);
  };

  const handleReport = async (uid, uname) => {
    await setUserData({
      userid: uid,
      username: uname,
    });
    await openDialog();
  };
  const rows = usersData;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    ...VISIBLE_FIELDS.map((field) => {
      if (field === "sr") {
        return {
          field,
          headerName: "Sr",
          width: 80,
          renderCell: (params) => <strong>{params.row.id}</strong>,
        };
      } else if (field === "receiverusername") {
        return {
          field,
          headerName: "Username",
          width: 150,
        };
      } else if (field === "payoutmethod") {
        return {
          field,
          headerName: `Payout Method`,
          width: 150,
          renderCell: (params) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value === "Matic" ? (
                  <img
                    src={`${ASSET_IMAGES}/maticicon.png`}
                    style={{ width: "28px" }}
                  />
                ) : (
                  <img
                    src={`${ASSET_IMAGES}/dollaricon.png`}
                    style={{ width: "25px" }}
                  />
                )}
                <ArrowRightAlt />
                <img
                  src={`${ASSET_IMAGES}/maticicon.png`}
                  style={{ width: "28px" }}
                />
              </div>
            );
          },
        };
      } else if (field === "amount") {
        return {
          field,
          headerName: `Amount`,
          width: 150,
          renderCell: (params) => {
            return params.row.payoutmethod === "Matic"
              ? params.value + " Matic"
              : "$" + params.value;
          },
        };
      } else if (field === "createdat") {
        return {
          field,
          headerName: "Date",
          width: 150,
          renderCell: (params) => {
            return format(new Date(params.row.createdat), "MM/dd/yyyy");
          },
        };
      } else if (field === "payoutaccount2") {
        return {
          field,
          headerName: "Payout Address",
          width: 200,
          renderCell: (params) => {
            const isCopied = copiedRows.includes(params.row.id);

            const handleCopyClick = () => {
              navigator.clipboard
                .writeText(params.value)
                .then(() => {
                  setCopiedRows((prevCopiedRows) => [
                    ...prevCopiedRows,
                    params.row.id,
                  ]);
                })
                .catch((error) => {
                  console.error("Copy failed:", error);
                });
            };

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {isCopied ? (
                  <CheckCircleIcon
                    style={{ marginRight: "4px", color: "green" }}
                  />
                ) : (
                  <Tooltip title="Copy Hash" placement="top">
                    <IconButton
                      onClick={handleCopyClick}
                      size="small"
                      style={{ marginRight: "4px" }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <span>{params.value}</span>
              </div>
            );
          },
        };
      } else if (field === "social") {
        return {
          field,
          headerName: `Social Proof`,
          width: 200,
          renderCell: (params) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.row.payoutmethod === "Matic" ? (
                  <>
                    <Stack direction="column" spacing={0}>
                      <Chip
                        label="Instagram"
                        size="small"
                        sx={{ backgroundColor: "red" }}
                        onClick={() =>
                          handleSocialClick(
                            params.row.tid,
                            "instagram"
                          )
                        }
                      />
                      <Chip
                        label="Twitter"
                        size="small"
                        sx={{ backgroundColor: "black" }}
                        onClick={() =>
                          handleSocialClick(
                            params.row.tid,
                            "twitter"
                          )
                        }
                      />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                      <Chip
                        label="Twitter Post"
                        size="small"
                        sx={{ backgroundColor: "blue" }}
                        onClick={() =>
                          handleSocialClick(
                            params.row.tid,
                            "post"
                          )
                        }
                      />
                      <Chip
                        label={params.row.twitterusername || "NA"}
                        size="small"
                        sx={{ backgroundColor: "black" }}
                      />
                    </Stack>
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          },
        };
      }
      return {
        field,
        headerName: field,
        width: 150,
      };
    }),
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title="Current Week Report" placement="top">
            <IconButton
              onClick={() =>
                handleReport(params.row.receiverid, params.row.receiverusername)
              }
              sx={{ color: "warning.main" }}
            >
              <Assessment />
            </IconButton>
          </Tooltip>

          <Tooltip title="Approve" placement="top">
            <IconButton
              onClick={() =>
                handleActionClick(params.row.tid, params.row.amount, "approved")
              }
              sx={{ color: "success.main" }}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton
              onClick={() =>
                handleActionClick(params.row.tid, params.row.amount, "rejected")
              }
              sx={{ color: "error.main" }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];


  const handleSocialClick = (id, action) => {
    const rowToEdit = usersData.find((row) => row.id === id);
    setRowData(rowToEdit);
    if (action === "instagram") {
      let image = socialProofUrl + rowToEdit?.instagramproof
      setSocialProof(image);
      setSocialStatus('Instagram');
      handleClickOpen();
    } else if (action === "twitter") {
      let image = socialProofUrl + rowToEdit?.twitterproof
      setSocialProof(image);
      setSocialStatus('Twitter');
      handleClickOpen();
    } else if (action === "post") {
      let image = socialProofUrl + rowToEdit?.postproof
      console.log('post proof', image)
      setSocialProof(image);
      setSocialStatus('Twitter Post');
      handleClickOpen();
    }

  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const allcolumns =
    loginUserData?.miniadmin === "yes" && loginUserData?.usertype === "user"
      ? columns.filter((column) => column.field !== "actions")
      : columns;

  return (
    <JumboDemoCard
      title={"Pending payouts"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Weekly Report</DialogTitle>
        <DialogContent>
          <WeeklyReport userData={userData || []} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => row.id}
          columns={allcolumns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Social Proof
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Div>
            <Typography>Username: {rowData?.receiverusername}</Typography>
          </Div>
          <Div>
            <Typography>{socialStatus}</Typography>
            <img src={socialProof} alt="Social Proof" />
          </Div>
        </DialogContent>
      </BootstrapDialog>
    </JumboDemoCard>
  );
};

export default PendingPayout;
