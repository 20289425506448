import React, { useEffect } from 'react';
import List from "@mui/material/List";
import {Autocomplete, Box,TextField, FormControl, Grid} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import {Form, Formik} from "formik";
import * as yup from "yup";
import { LoadingButton } from '@mui/lab';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import {postRequest} from 'backendServices/ApiCalls';
import { useState } from 'react';
import { CKEditor } from "ckeditor4-react"; // Import CKEditor


const validationSchema = yup.object({
    title: yup
        .string('Enter title')
        .required('title is required'),
    message: yup
        .string('Enter message')
        .required('message is required'),
        
  });

const SendRankMessage = ({rowData,handleCloseMessageDialog}) => {

    const [alertData, setalertData] = React.useState({
        show:false,
        message:"",
        variant:"" 
      })

      const [usersdata,setUsersData]=useState([])
      let params = {
        status:'all',
      }

      
      const onSubmitForm = (data,setSubmitting,resetForm) => {
        let params = {
            userid : rowData?.userid,
            rowid : rowData?.id,
            title :data.title,
            message :data.message
          }
          console.log('ppppppp', params)
          postRequest('/sendrankmessage',params, (response) => {
            if( response?.data?.status === "error"){
              setalertData({
                show:true,
                message:response?.data?.message,
                variant:"error"
            }) 
            setSubmitting(false)
            }else if(response?.data?.status === "success"){
          
              setalertData({
                show:true,
                message:response?.data?.message + " to "+rowData?.username,
                variant:"success"
            })
            setSubmitting(false)
            resetForm()
            handleCloseMessageDialog()
            }
           
          }, (error) => {
            console.log(error?.response?.data);
          })
          
    } 
      

    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
        
         {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }

             <Formik
                validateOnChange={true}
                enableReinitialize = 'true'
                initialValues={{
                title: rowData?.messagetitle,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting,resetForm}) => {
                            setSubmitting(true);
                            onSubmitForm(data, setSubmitting,resetForm);
                        }}
            >
{({isSubmitting, setFieldValue,values}) => (
            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>


<Grid container spacing={2}>

          <Grid item xs={12}>
                <JumboTextField
                fullWidth
                    name='title'
                    label='Enter Title'
                    type='text'
                    />
        </Grid>

        <Grid item xs={12}>
        <CKEditor
          initData={rowData?.adminmessage}
          onChange={(event) => setFieldValue("message", event.editor.getData())}
        />
        </Grid>

        <Grid item xs={12}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >Send</LoadingButton>
                            </Grid>
            </Grid>

            </Form>
             )}
             </Formik>
        </Grid>
        </Grid>
    );
};

export default SendRankMessage;