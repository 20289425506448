import React, { useState, useEffect, useRef } from 'react'
import { Container, Grid, TextField, Button, Typography, Paper, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { postRequest } from 'backendServices/ApiCalls';

const ChatBox = ({selectedMessage, setLoadingRows}) => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const lastMessageRef = useRef(null);
  
    const sendMessage =()=>{
        if (!message.trim()) return;
        setSending(true)
        let params = {
            randomcode:selectedMessage?.randomcode,
            message:message.replace(/\n/g, '<br />'),
            title:selectedMessage?.title,
            userid:selectedMessage?.receiver === 'admin' ? selectedMessage?.sender : selectedMessage?.receiver
        }
      postRequest('/sendmessage',params,(response) => {
        setMessages([...messages, response.data?.data]);
        setMessage('');
        setSending(false)
        }, (error) => {
              console.log(error?.response?.data); 
          })
      }

useEffect(()=>{
    postRequest('/getChat',{msgCode:selectedMessage?.randomcode},(response) => {
        setMessages(response?.data?.data)
        setLoadingRows(prevLoadingRows => ({
          ...prevLoadingRows,
          [selectedMessage.id]: false,
        }));
    }, (error) => {
        console.log(error?.response?.data); 
    })
},[])

useEffect(() => {
  // Scroll to last message when messages update
  if (lastMessageRef.current) {
    lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [messages]);


const handleKeyPress = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Prevent default behavior of submitting the form
    sendMessage();
  }
  };

const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Sender: {selectedMessage?.senderusername}</Typography>
          <Typography variant="h4">{selectedMessage?.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px', maxHeight: '50vh', overflowY: 'auto' }}>
            {messages.map((message, index) => (
              <div key={index} ref={index === messages.length - 1 ? lastMessageRef : null} style={{ textAlign: message.sender === 'admin' ? 'right' : 'left' }}>

                <div style={{ wordWrap: 'break-word' }}>
                  <Typography variant="body1" sx={{ display: 'inline-block',maxWidth: ['80%', '50%'], wordWrap: 'break-word', padding: '2px 8px', backgroundColor: message.sender === 'admin' ? '#DCF8C6' : '#E9EBED', borderRadius: '8px', color:'#000',textAlign:'left' }}>
                   <Typography sx={{fontSize:'10px', fontWeight:'bold', marginBottom:'10px'}}>({message.sender === 'admin' ? 'You' : message.senderusername})</Typography>
                    <div dangerouslySetInnerHTML={{ __html: message.message }} />
                  </Typography>
                </div>

                <Typography variant="caption" style={{ display: 'block', marginBottom: '4px' }}>
                  {formatDateTime(message.createdat)} 
                </Typography>

              </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Type a message..." 
            variant="outlined" 
            value={message} 
            onChange={e => setMessage(e.target.value)} 
            fullWidth
            multiline
            rows={2}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <IconButton onClick={sendMessage}>
                  {
                    sending ? (
                      <CircularProgress size={24} />
                    )
                    :
                    (
                      <SendIcon color='success' />
                    )
                  }
                  
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ChatBox